import { getType } from 'typesafe-actions'
import { persistReducer } from 'utils/ReduxUtils'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { set } from 'lodash/fp'
import SessionActions from './SessionActions'

const initialState = {
  userId: null,
  userToken: null,
  refreshToken: null,
  profileComplete: null,
  email: null,
}

export const persistConfig = {
  key: 'session',
  timeout: null,
  stateReconciler: autoMergeLevel2,
}

export const SessionReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case getType(SessionActions.setSession):
      return {
        ...state,
        userId: action.payload.userid,
        userToken: action.payload.login_token,
        refreshToken: action.payload.refresh_token,
        profileComplete: action.payload.profile_complete,
        email: action.payload?.email,
      }
    case getType(SessionActions.setProfileComplete):
      return set('profileComplete', action.payload.profileComplete, state)
    case getType(SessionActions.logout):
      return {
        ...state,
        userId: null,
        userToken: null,
        refreshToken: null,
        profileComplete: null,
        email: null,
      }
    default:
      return state
  }
})
