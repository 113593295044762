import { createAsyncAction, getType } from 'typesafe-actions'
import { Platform } from 'react-native'
import { combineEpics, ofType } from 'redux-observable'
import { postEpic } from 'data/network'
import { map, filter, ignoreElements, tap, debounceTime, delayWhen } from 'rxjs/operators'
import { get, reduce } from 'lodash/fp'
import { persistReducer } from 'utils/ReduxUtils'
import { getUniqueId } from 'react-native-device-info'
import analytics from 'data/analytics'
import SessionActions from 'features/session/store/SessionActions'
import { InviteActions } from 'features/invite/store/actions'
import { selectIsPartnerConnected } from 'features/invite/store/InvitePartnerSelectors'
import { NetworkActions } from 'network/store'
import { combineReducers } from 'redux'
import { interval } from 'rxjs'

export const WEB_UNIQUE_ID = 'relish_web_device_unique_id'

export const ExperimentActions = {
  requestUserExperiments: createAsyncAction(
    'experiments/USER_EXPERIMENTS/REQUEST',
    'experiments/USER_EXPERIMENTS/RECEIVE_SUCCESS',
    'experiments/USER_EXPERIMENTS/RECEIVE_FAILURE'
  )(),
  requestDeviceExperiments: createAsyncAction(
    'experiments/DEVICE_EXPERIMENTS/REQUEST',
    'experiments/DEVICE_EXPERIMENTS/SUCCESS',
    'experiments/DEVICE_EXPERIMENTS/FAILURE'
  )(),
  requestUserPartnerExperiments: createAsyncAction(
    'experiments/USER_PARTNER_EXPERIMENTS/REQUEST',
    'experiments/USER_PARTNER_EXPERIMENTS/SUCCESS',
    'experiments/USER_PARTNER_EXPERIMENTS/FAILURE'
  )(),
}

const selectExperimentsForAnalytics = (experiments, isPartner) => {
  return reduce(
    (result, value) => {
      result[`${isPartner ? '[exp-partner-group]' : '[exp]'} ${value}`] =
        experiments[value]?.userTreatment
      return result
    },
    {},
    Object.keys(experiments)
  )
}

const selectHasExperimentBeenFetched = (state, experimentType) =>
  get(`${experimentType}.fetched`, state.experiments)

const FetchDeviceExperimentsOnLaunch = (action$) =>
  action$.pipe(
    ofType(getType(SessionActions.appLaunch)),
    map(() => ExperimentActions.requestDeviceExperiments.request())
  )

export const SetAmplitudeUserPropertiesOnExperimentsFetch = (experimentsType, asyncAction) => (
  action$,
  state$
) =>
  action$.pipe(
    ofType(getType(asyncAction.success)),
    tap(() => {
      analytics.setUserProperties(
        selectExperimentsForAnalytics(
          state$.value?.experiments?.[experimentsType]?.experiments ?? {},
          experimentsType === 'partner'
        )
      )
    }),
    ignoreElements()
  )

export const RetryFetchExperimentsOnConnectivityUpdate = (experimentsType, asyncAction) => (
  action$,
  state$
) =>
  action$.pipe(
    ofType(getType(NetworkActions.connectivityUpdate)),
    filter(({ payload }) => payload.isInternetReachable),
    filter(() => state$.value?.experiments?.[experimentsType]?.retry),
    debounceTime(5000),
    map(() => asyncAction.request())
  )

export const RetryFetchExperimentsOnFailureWithDelay = (experimentsType, asyncAction) => (
  action$,
  state$
) =>
  action$.pipe(
    ofType(getType(asyncAction.failure)),
    delayWhen(() => interval(state$.value?.experiments?.[experimentsType]?.retryDelay)),
    map(() => asyncAction.request({ isRetry: true }))
  )

const FetchExperimentsOnLogin = (action$) =>
  action$.pipe(
    ofType(getType(SessionActions.setSession)),
    map(() => ExperimentActions.requestUserExperiments.request())
  )

const FetchUserPartnerExperimentsWhenConnected = (action$, state$) =>
  action$.pipe(
    ofType(getType(InviteActions.fetchPartnerConnectMessage.success)),
    filter(
      () =>
        selectIsPartnerConnected(state$.value) &&
        !selectHasExperimentBeenFetched(state$.value, 'partner')
    ),
    map(() => ExperimentActions.requestUserPartnerExperiments.request())
  )

const FetchDeviceExperimentsOnLogout = (action$) =>
  action$.pipe(
    ofType(getType(SessionActions.logout)),
    map(() => ExperimentActions.requestDeviceExperiments.request())
  )

export const ExperimentEpicFactory = (networkClient) =>
  combineEpics(
    FetchExperimentsOnLogin,
    FetchDeviceExperimentsOnLogout,
    FetchDeviceExperimentsOnLaunch,
    FetchUserPartnerExperimentsWhenConnected,
    SetAmplitudeUserPropertiesOnExperimentsFetch(
      'device',
      ExperimentActions.requestDeviceExperiments
    ),
    SetAmplitudeUserPropertiesOnExperimentsFetch('user', ExperimentActions.requestUserExperiments),
    SetAmplitudeUserPropertiesOnExperimentsFetch(
      'partner',
      ExperimentActions.requestUserPartnerExperiments
    ),
    RetryFetchExperimentsOnConnectivityUpdate('device', ExperimentActions.requestDeviceExperiments),
    RetryFetchExperimentsOnConnectivityUpdate('user', ExperimentActions.requestUserExperiments),
    RetryFetchExperimentsOnConnectivityUpdate(
      'partner',
      ExperimentActions.requestUserPartnerExperiments
    ),
    RetryFetchExperimentsOnFailureWithDelay('device', ExperimentActions.requestDeviceExperiments),
    RetryFetchExperimentsOnFailureWithDelay('user', ExperimentActions.requestUserExperiments),
    RetryFetchExperimentsOnFailureWithDelay(
      'partner',
      ExperimentActions.requestUserPartnerExperiments
    ),
    postEpic(networkClient, ExperimentActions.requestUserExperiments, () => '/experiments/:userid'),
    postEpic(
      networkClient,
      ExperimentActions.requestDeviceExperiments,
      () =>
        `/device-experiments/${
          Platform.OS === 'web' ? window.localStorage.getItem(WEB_UNIQUE_ID) : getUniqueId()
        }`
    ),
    postEpic(
      networkClient,
      ExperimentActions.requestUserPartnerExperiments,
      () => '/experiments/user-partner/:userid'
    )
  )

const createExperimentsReducer = (asyncAction) => (
  state = { experiments: {}, fetched: false, retry: false, retryDelay: 500, retryCount: 0 },
  action
) => {
  switch (action.type) {
    case getType(asyncAction.success): {
      return {
        ...state,
        experiments: { ...state.experiments, ...action.payload.response },
        fetched: true,
        retry: false,
        retryDelay: 500,
        retryCount: 0,
      }
    }
    case getType(asyncAction.failure):
      return {
        ...state,
        retry: true,
      }
    case getType(asyncAction.request):
      return {
        ...state,
        ...(action?.payload?.isRetry
          ? {
              retryCount: state.retryCount + 1,
              retryDelay: state.retryDelay * 2 ** (state.retryCount + 1),
            }
          : {}),
      }
    default:
      return state
  }
}

const userExperimentsPersistConfig = {
  key: 'userExperiments',
  blacklist: ['fetched'],
}

const userPartnerExperimentsPersistConfig = {
  key: 'userPartnerExperiments',
  blacklist: ['fetched'],
}

export const ExperimentsReducer = combineReducers({
  device: createExperimentsReducer(ExperimentActions.requestDeviceExperiments),
  user: persistReducer(
    userExperimentsPersistConfig,
    createExperimentsReducer(ExperimentActions.requestUserExperiments)
  ),
  partner: persistReducer(
    userPartnerExperimentsPersistConfig,
    createExperimentsReducer(ExperimentActions.requestUserPartnerExperiments)
  ),
})

export const selectUserExperiments = (state) => state.experiments.user.experiments

export const selectUserExperimentsFetched = (state) => state.experiments.user.fetched

export const selectDeviceExperimentsFetched = (state) => state.experiments.device.fetched

export const selectInCoachMessagingTreatment = (state) =>
  selectUserExperiments(state)?.inAppCoaching?.userTreatment === 'in-app-coaching'

export const selectEasyPartnerShareTreatment = (state) =>
  selectUserExperiments(state)?.easyPartnerShareNotes20220120?.userTreatment ===
  'easy-partner-share-notes-2022-01-20'
