const Pinterest = {
  init(id) {
    /* eslint-disable */
    !function(e){if(!window.pintrk){window.pintrk = function () {
      window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    /* eslint-enable */
    window?.pintrk?.('load', id)
  },

  pageView() {
    window?.pintrk?.('page')
  },

  track(eventName, eventData) {
    window?.pintrk?.('track', eventName, eventData)
  },
}

export default Pinterest
