import { createAsyncAction, createAction } from 'typesafe-actions'

export const OnboardActions = {
  collect: createAction('onboard/COLLECT')(),
  collectMultiple: createAction('onboard/COLLECT_MULTIPLE')(),
  nextQuestion: createAction('onboard/NEXT_QUESTION')(),
  prevQuestion: createAction('onboard/PREV_QUESTION')(),
  resetQuestions: createAction('onboard/RESET_QUESTIONS')(),
  requestPostProfile: {
    ...createAsyncAction(
      'onboard/POST_PROFILE/REQUEST',
      'onboard/POST_PROFILE/SUCCESS',
      'onboard/POST_PROFILE/FAILURE'
    )(),
    reset: createAction('onboard/POST_PROFILE/RESET')(),
  },
  retakeProfile: createAction('onboard/RETAKE_PROFILE')(),
  retakeProfileReset: createAction('onboard/RETAKE_PROFILE/RESET')(),
  retakeOnboard: createAction('onboard/RETAKE_ONBOARD')(),
  fetchSections: createAsyncAction(
    'onboard/FETCH_SECTIONS/REQUEST',
    'onboard/FETCH_SECTIONS/SUCCESS',
    'onboard/FETCH_SECTIONS/FAILURE'
  )(),
  generatingNewInsights: createAction('onboard/GENERATE_NEW_INSIGHTS')(),
  showNewInsights: createAction('onboard/SHOW_NEW_INSIGHTS')(),
  commitNewSections: createAction('onboard/COMMIT_NEW_SECTIONS')(),
  requestUpdateIdentity: {
    ...createAsyncAction(
      'onboard/UPDATE_IDENTITY/REQUEST',
      'onboard/UPDATE_IDENTITY/SUCCESS',
      'onboard/UPDATE_IDENTITY/FAILURE'
    )(),
    reset: createAction('onboard/UPDATE_IDENTITY/RESET')(),
  },
  notificationFormCompleted: createAction('onboard/NOTIFICATION_FORM_COMPLETED')(),
  displayPaywall: createAction('onboard/DISPLAY_PAYWALL')(),
}
