import { combineReducers } from 'redux'
import { createAction, getType } from 'typesafe-actions'

export const NetworkActions = {
  connectivityUpdate: createAction('connectivity/UPDATE')(),
}

export const NetworkReducer = combineReducers({
  connectivity: (state = null, action) => {
    switch (action.type) {
      case getType(NetworkActions.connectivityUpdate):
        return action.payload
      default:
        return state
    }
  },
})

export const hasConnectivity = (state) =>
  state.network.connectivity != null &&
  state.network.connectivity.type !== 'none' &&
  state.network.connectivity.type !== 'unknown'
