import { get } from 'lodash/fp'
import { OnboardQuestionSource } from 'features/onboard/screens/OnboardQuestionSource'

export const screenEventMap = {
  Profile: 'Profile',
  Settings: 'Settings',
  IndividualAttachment: 'Individual Attachment',
  Current: 'Your Plan',
  Journal: 'History Journal',
  PartnerConfirm: 'Accept Invite',
  InvitePartner: 'Partner Invite',
  YourProgress: 'Your Progress',
  AboutYou: 'About You',
  OnboardScreen: 'Onboard Signup',
  RetakeProfile: 'Retake Profile Section',
}

export const screenEventParamsMap = {
  InvitePartner: (params) => ({ Source: get('Source', params) }),
  PartnerConfirm: (params) => ({ Source: get('Source', params) }),
  RetakeProfile: (params) => ({
    sectionName: get('sectionName', params),
  }),
}

export const screenEventNamesMap = {
  OnboardScreen: (params) =>
    get('source', params) === OnboardQuestionSource.retake
      ? 'Retake Questionnaire'
      : 'Questionnaire',
}
