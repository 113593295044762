import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, Image } from 'react-native'
import R from 'shared/src/assets/R'
import { useWindowDimensions } from 'react-native'
import { WEB_MAX_HEIGHT, WEB_MAX_WIDTH } from 'shared/src/utils/ScreenUtils'

const propTypes = {
  children: PropTypes.node,
}

const RootChrome = ({ children }) => {
  const { width } = useWindowDimensions()
  const isWide = width > WEB_MAX_WIDTH
  return (
    <View style={styles.appContainer}>
      {Boolean(isWide) && <Image style={styles.logo} source={R.images.text_relish_logo} />}
      <View style={[styles.appWrapper, isWide ? { margin: 8 } : null]}>{children}</View>
    </View>
  )
}
RootChrome.propTypes = propTypes

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: R.colors.paleBlue,
  },
  appWrapper: {
    flex: 1,
    width: '100%',
    height: '100%',
    maxWidth: WEB_MAX_WIDTH,
    maxHeight: WEB_MAX_HEIGHT,
    shadowColor: R.colors.black_50_percent,
    shadowOpacity: 0.4,
    shadowRadius: 5,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
  },
  logo: {
    margin: 16,
    alignSelf: 'flex-start',
  },
})

export default RootChrome
