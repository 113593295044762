import { merge, getOr } from 'lodash/fp'

export const httpStatusCodes = {
  errorCodes: [0, 400, 500, 409],
}

export const apiError = (status) => {
  return httpStatusCodes.errorCodes.includes(status)
}

export const errorDocument = (apiPayload, defaultMessage = null) => {
  const genericDefaultMessage = "Sorry, there's been a problem"
  if (apiError(getOr(-1, 'status', apiPayload))) {
    const responseTitle = getOr('', 'response.title', apiPayload)
    const responseMessage = getOr('', 'response.message', apiPayload)
    const title = responseTitle ? responseTitle : ''
    const message =
      defaultMessage != null
        ? defaultMessage
        : responseMessage
        ? responseMessage
        : genericDefaultMessage
    return merge(apiPayload.response, {
      title: title,
      message: message,
    })
  }
  return { title: '', message: genericDefaultMessage }
}
