import { createRef } from 'react'
import { StackActions } from '@react-navigation/native'

export const navigationRef = createRef()
const navigate = (routeName, params) => navigationRef.current?.navigate(routeName, params)
const replace = (routeName, params) =>
  navigationRef.current?.dispatch(StackActions.replace(routeName, params))

export default {
  navigate,
  replace,
}
