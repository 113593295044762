import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { LoginActions } from './actions'

const magicLogin = (state = { status: null, data: null, error: null }, action) => {
  switch (action.type) {
    case getType(LoginActions.magicLogin.request):
      return {
        ...state,
        status: null,
        error: null,
        data: null,
      }
    case getType(LoginActions.magicLogin.success):
      return {
        status: action.payload.status,
        data: action.payload.response,
        error: null,
      }
    case getType(LoginActions.magicLogin.failure):
      return {
        status: action.payload.status,
        data: null,
        error: action.payload.response,
      }
    case getType(LoginActions.magicLoginReset):
      return {
        ...state,
        status: null,
        data: null,
        error: null,
      }
    default:
      return state
  }
}

export const LoginReducer = combineReducers({
  magicLogin: magicLogin,
})
