import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import { map, filter, tap, ignoreElements } from 'rxjs/operators'
import { has } from 'lodash/fp'
import { InviteActions } from './actions'
import { fetchEpic, postEpic } from 'data/network'
import analytics from 'data/analytics'
import { OnboardActions } from 'features/onboard/store/OnboardActions'

export const FetchPartnerConnectMessageWhenItNeedsRefreshed = (action$) =>
  action$.pipe(
    ofType(
      getType(InviteActions.requestDisconnect.success),
      getType(OnboardActions.requestPostProfile.success)
    ),
    map(() => InviteActions.fetchPartnerConnectMessage.request())
  )

export const InAppMessageShownAnalyticOnPartnerConnectMessageSuccess = (action$) =>
  action$.pipe(
    ofType(getType(InviteActions.fetchPartnerConnectMessage.success)),
    filter(({ payload }) => has('message.analytic', payload)),
    tap(({ payload }) =>
      analytics.logEvent('In-App Message Shown', { Type: payload.message.analytic })
    ),
    ignoreElements()
  )

// TODO: Reduce this as part of token connection success
const FetchPartnerConnectStatusAfterTokenConnectionRequestSuccess = (action$) =>
  action$.pipe(
    ofType(getType(InviteActions.requestTokenConnect.success)),
    map(() => InviteActions.fetchPartnerConnectMessage.request())
  )

export const InvitePartnerEpicFactory = (networkClient) =>
  combineEpics(
    FetchPartnerConnectMessageWhenItNeedsRefreshed,
    InAppMessageShownAnalyticOnPartnerConnectMessageSuccess,
    FetchPartnerConnectStatusAfterTokenConnectionRequestSuccess,
    postEpic(networkClient, InviteActions.requestDisconnect, () => `/invite/:userid/disconnect`),
    fetchEpic(
      networkClient,
      InviteActions.fetchPartnerConnectMessage,
      () => `/invite/:userid/partner-connect-status`
    ),
    postEpic(
      networkClient,
      InviteActions.fetchCreateInvite,
      () => `/v2/invite/:userid/create-invite`
    ),
    fetchEpic(
      networkClient,
      InviteActions.createFriendReferral,
      () => `/invite/:userid/create-friend-referral`
    ),
    postEpic(
      networkClient,
      InviteActions.requestTokenConnect,
      () => `/connect-users/:userid`,
      ({ token }) => ({ token })
    )
  )
