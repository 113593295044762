import { endsWith } from 'lodash/fp'

export const personaliseQuestion = (question, collected) => {
  var pQuestion = Object.assign({}, question)
  return personalisedItem(pQuestion, collected)
}

export const personalisedText = (text, collected) => {
  let value
  if (text.includes('<partner_name_apostrophise>')) {
    const partnerName = endsWith('s', collected['partner_name'])
      ? `${collected['partner_name']}'`
      : `${collected['partner_name']}'s`
    value = text.replace(/<partner_name_apostrophise>/g, partnerName)
  } else {
    value = text.replace(/<partner name>/g, collected['partner_name'])
  }

  for (var key in collected) {
    if (typeof collected[key] === 'string') {
      let collectedValue = collected[key]
      let replace = '<' + key + '>'
      var re = new RegExp(replace, 'g')
      value = value.replace(re, collectedValue)
    }
  }
  return value
}

export const personalisedItem = (item, collected) => {
  var updatedItem = {}
  if (typeof item === 'string') {
    return personalisedText(item, collected)
  }
  for (var key in item) {
    if (typeof item[key] === 'string') {
      updatedItem = {
        ...updatedItem,
        [key]: personalisedText(item[key], collected),
      }
    } else if (parseInt(item[key]) || typeof item[key] === 'boolean') {
      updatedItem = { ...updatedItem, [key]: item[key] }
    } else if (Array.isArray(item[key])) {
      updatedItem = {
        ...updatedItem,
        [key]: item[key].map(function (item) {
          return personalisedItem(item, collected)
        }),
      }
    } else if (typeof item[key] === 'object') {
      updatedItem = {
        ...updatedItem,
        [key]: personalisedItem(item[key], collected),
      }
    } else {
      updatedItem = { ...updatedItem, [key]: item[key] }
    }
  }
  return updatedItem
}
