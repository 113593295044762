import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import { map, tap, ignoreElements, filter } from 'rxjs/operators'
import analytics from 'shared/src/data/analytics'
import marketing from 'shared/src/features/marketing'
import { BillingActions as SharedBillingActions } from 'shared/src/features/billing/store/actions'

export const PresentPaywall = (action$) =>
  action$.pipe(
    ofType(getType(SharedBillingActions.fetchPaywall.success)),
    map(({ payload: { paywall } }) => SharedBillingActions.presentPaywall({ paywall }))
  )

export const BeginPurchase = (action$) =>
  action$.pipe(
    ofType(getType(SharedBillingActions.beginPurchase)),
    tap(({ payload }) =>
      analytics.logEvent('Purchase Flow Started', {
        SKU: payload.skuDefinition.sku,
        Position: payload.position,
        Option: payload.option,
      })
    ),
    map(({ payload: { skuDefinition, redirectPath } }) =>
      SharedBillingActions.createCheckoutSession.request({
        priceId: skuDefinition.stripePriceId,
        redirectPath,
      })
    )
  )

export const RedirectToStripeCheckout = (action$) =>
  action$.pipe(
    ofType(getType(SharedBillingActions.createCheckoutSession.success)),
    map(async ({ payload }) => {
      const { stripePublishableKey, sessionId } = payload.response
      const { loadStripe } = await import('@stripe/stripe-js')
      const stripe = await loadStripe(stripePublishableKey)
      await stripe.redirectToCheckout({ sessionId })
    }),
    ignoreElements()
  )

export const MarketingStartTrialOnPurchaseComplete = (action$) =>
  action$.pipe(
    ofType(getType(SharedBillingActions.purchaseComplete)),
    filter((action) => action.payload),
    tap(() => marketing.startTrial()),
    ignoreElements()
  )

export const BillingEpicFactory = () =>
  combineEpics(
    BeginPurchase,
    PresentPaywall,
    RedirectToStripeCheckout,
    MarketingStartTrialOnPurchaseComplete
  )
