import { get, isNil, getOr } from 'lodash/fp'

export const selectInvite = (state) => get('invite', state)

export const selectDisconnectingRequestInProgress = (state) =>
  get('requestsInProgress.disconnect', selectInvite(state))

export const selectPartnerConnectMessage = (state) => get('connectStatus', selectInvite(state))

export const selectInvitation = (state) => get('invitation', selectInvite(state))

export const selectInviteStatus = (state) =>
  get('connectStatus.connectionDetails', selectInvite(state))

export const selectDisconnectRequestResponse = (state) =>
  get('disconnectResponse', selectInvite(state))

export const selectIsPartnerConnected = (state) =>
  get('connectStatus.status', selectInvite(state)) === 'connected'

export const selectInvitePartnerAlert = (state) => {
  const invitePartner = getOr(null, 'connectStatus.shareWithPartner.invite', selectInvite(state))
  const alert = invitePartner
    ? {
        anim: invitePartner.invitePartnerAnim,
        title: invitePartner.invitePartnerTitle,
        message: invitePartner.invitePartnerMessage,
        positiveCta: invitePartner.invitePartnerPositiveCta,
        negativeCta: invitePartner.invitePartnerNegativeCta,
        positiveDeeplink: invitePartner.invitePartnerDeeplinkUrl,
      }
    : null
  return alert
}

export const selectShouldShowInvitePartnerAlert = (state) => {
  return !isNil(get('connectStatus.shareWithPartner.invite', selectInvite(state)))
}

export const selectPartnerConnectionToken = (state) =>
  get('partnerConnectionToken', selectInvite(state))
