// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html, body {\n  height: 100%;\n}\n\n#react-root {\n  display: flex;\n  flex: 1 1 100%;\n  height: 100%;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./global.css"],"names":[],"mappings":"AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,WAAW;AACb","sourcesContent":["@import './fonts.css';\n\nhtml, body {\n  height: 100%;\n}\n\n#react-root {\n  display: flex;\n  flex: 1 1 100%;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
