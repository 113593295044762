import { consoleWarn } from 'utils/logging'
import { omitBy, isNil, isEmpty } from 'lodash/fp'
import ReactPixel from 'react-facebook-pixel'
import Pinterest from './Pinterest'

const login = (userId) => consoleWarn('marketing', `AppsFlyer login mock. userId: ${userId}`)

const completeRegistration = () => {
  if (getAttributionId() === '6a7be325-fb1f-41f4-adff-2bf473874e19') {
    Pinterest.track('Signup')
  }
}

const startTrial = () => {
  if (getAttributionId() === '6a7be325-fb1f-41f4-adff-2bf473874e19') {
    Pinterest.track('Checkout')
  }
}

const logEvent = (event, userId) =>
  consoleWarn('marketing', `AppsFlyer logEvent mock. event ${event}, userId: ${userId}`)

export const ATTRIBUTION_ID = 'at_id'
const getAttributionId = () => window.localStorage.getItem(ATTRIBUTION_ID)

const FACEBOOK_FBC = 'fbclid'
const getFBC = () => window.localStorage.getItem(FACEBOOK_FBC)

const GOOGLE_GCLID = 'gclid'
const getGCLID = () => window.localStorage.getItem(GOOGLE_GCLID)

const storeParam = (key) => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlParamValue = urlParams.get(key)
  if (urlParamValue) {
    window.localStorage.setItem(key, urlParamValue)
  }
}

const UTM_PARAMS = 'utm'
const getUTM = () => window.localStorage.getItem(UTM_PARAMS)

const storeUTM = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const utmParams = {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_term: urlParams.get('utm_term'),
    utm_content: urlParams.get('utm_content'),
  }
  const minUtmParams = omitBy(isNil, utmParams)
  if (!isEmpty(minUtmParams)) {
    window.localStorage.setItem(UTM_PARAMS, JSON.stringify(minUtmParams))
  }
}

const init = () => {
  storeParam(ATTRIBUTION_ID)
  storeUTM()
  switch (getAttributionId()) {
    case '63ed32d8-effc-4479-b21a-f0ce83417810':
      ReactPixel.init('1693409954107361', {}, { autoConfig: true, debug: false })
      ReactPixel.pageView()
      storeParam(FACEBOOK_FBC)
      break
    case 'b9e8c89a-3bfa-4dbf-82fa-61b564cadb1b':
      storeParam(GOOGLE_GCLID)
      break
    case '6a7be325-fb1f-41f4-adff-2bf473874e19':
      Pinterest.init('2612420933895')
      Pinterest.pageView()
      break
  }
}

export default {
  init,
  login,
  completeRegistration,
  startTrial,
  logEvent,
  getAttributionId,
  getFBC,
  getUTM,
  getGCLID,
}
