import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import { tap, ignoreElements, filter } from 'rxjs/operators'
import { LoginActions } from 'shared/src/features/login/store/actions'
import { WEB_UNIQUE_ID } from 'shared/src/features/experiments/Experiments'
import analytics from 'shared/src/data/analytics'
import { ATTRIBUTION_ID } from 'shared/src/features/marketing'

export const setAmplitudeDeviceIdOnLogin = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.success)),
    filter(({ payload }) => payload.response.deviceId),
    tap(({ payload }) => {
      analytics.setDeviceId(payload.response.deviceId)
      window.localStorage.setItem(WEB_UNIQUE_ID, payload.response.deviceId)
    }),
    ignoreElements()
  )

export const setAttributionIdOnLogin = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.success)),
    filter(({ payload }) => payload.response.attributionId),
    tap(({ payload }) => {
      window.localStorage.setItem(ATTRIBUTION_ID, payload.response.attributionId)
    }),
    ignoreElements()
  )

export const LoginEpicFactory = () =>
  combineEpics(setAmplitudeDeviceIdOnLogin, setAttributionIdOnLogin)
