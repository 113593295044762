import { Platform } from 'react-native'
import { combineEpics, ofType } from 'redux-observable'
import { map, filter, ignoreElements } from 'rxjs/operators'
import { isNil } from 'lodash/fp'
import { getType } from 'typesafe-actions'

import { postEpic, fetchEpic } from 'data/network'
import { BillingActions } from './actions'
import NavigationService from 'features/navigation/NavigationService'
import { OnboardActions } from 'features/onboard/store/OnboardActions'
import { InviteActions } from 'features/invite/store/actions'

const shouldDelayUpsell = (state) =>
  state.billing.upsell == null || state.billing.verifyingPurchases
const hasShownOnboardingPaywall = (state) => state.billing.hasShownOnboardingPaywall
const shouldShowUpsell = (state, source) =>
  state.billing.upsell != null &&
  (state.billing.upsell.show_upsell || state.billing.upsell.show_upsell_for.includes(source))

const UpsellActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.upsellAction)),
    filter(
      ({ payload }) =>
        !shouldDelayUpsell(state$.value) && shouldShowUpsell(state$.value, payload.Source)
    ),
    map(({ payload }) => {
      NavigationService.navigate('Paywall', payload)
    }),
    ignoreElements()
  )

const NoUpsellRequiredActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.upsellAction)),
    filter(
      ({ payload }) =>
        !isNil(payload.onNoUpsellRequired) &&
        !shouldDelayUpsell(state$.value) &&
        !shouldShowUpsell(state$.value, payload)
    ),
    map(({ payload }) => payload.onNoUpsellRequired()),
    ignoreElements()
  )

const OnboardingPaywallEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.displayPaywall)),
    filter(() => !hasShownOnboardingPaywall(state$.value)),
    map(({ payload }) => BillingActions.upsellAction(payload))
  )

const OnboardingPaywallAlreadyShownEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.displayPaywall)),
    filter(() => hasShownOnboardingPaywall(state$.value)),
    filter(({ payload }) => !isNil(payload.onNoUpsellRequired)),
    map(({ payload }) => payload.onNoUpsellRequired()),
    ignoreElements()
  )

const DelayUpsellActionIfNoBillingDataOrVerifyingPurchasesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.upsellAction)),
    filter(() => shouldDelayUpsell(state$.value)),
    map(({ payload }) => BillingActions.delayUpsellAction(payload))
  )

const SyncPurchasesIfNoBillingDataEpic = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.upsellAction)),
    filter(() => state$.value.billing.upsell == null),
    map(() => BillingActions.verifyPurchases())
  )

const ReplayUpsellActionOnVerifyPurchaseSuccess = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.verifyPurchasesRequest.success)),
    filter(() => state$.value.billing.delayedUpsellAction != null),
    map(() => BillingActions.upsellAction(state$.value.billing.delayedUpsellAction.payload))
  )

const VerifyPurhcasesIfNotAlready = (action$, state$) =>
  action$.pipe(
    ofType(getType(BillingActions.verifyPurchases)),
    filter(() => !state$.value.billing.verifyingPurchases),
    map(() => BillingActions.verifyPurchasesRequest.request())
  )

const VerifyPurchasesAfterTokenConnectionRequestSuccess = (action$) =>
  action$.pipe(
    ofType(getType(InviteActions.requestTokenConnect.success)),
    map(() => BillingActions.verifyPurchases())
  )

export const BillingEpicFactory = (networkClient) =>
  combineEpics(
    UpsellActionEpic,
    NoUpsellRequiredActionEpic,
    OnboardingPaywallAlreadyShownEpic,
    OnboardingPaywallEpic,
    DelayUpsellActionIfNoBillingDataOrVerifyingPurchasesEpic,
    SyncPurchasesIfNoBillingDataEpic,
    ReplayUpsellActionOnVerifyPurchaseSuccess,
    VerifyPurhcasesIfNotAlready,
    VerifyPurchasesAfterTokenConnectionRequestSuccess,
    postEpic(
      networkClient,
      BillingActions.createCheckoutSession,
      () => `/billing/:userid/create-checkout-session`,
      ({ priceId, redirectPath }) => ({ priceId, redirectPath })
    ),
    fetchEpic(
      networkClient,
      BillingActions.fetchPaywall,
      (payload) =>
        `/v5/billing/:userid/paywall/${Platform.select({
          ios: 'ios',
          android: 'droid',
          web: 'web',
        })}?intent=${payload.intent}`
    ),
    postEpic(
      networkClient,
      BillingActions.verifyPurchasesRequest,
      () =>
        `/billing/:userid/verify-${Platform.select({
          ios: 'ios',
          android: 'droid',
          web: 'web',
        })}`
    )
  )
