import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { reduceReducers } from 'shared/src/utils/ReduxUtils'

import SessionActions from 'shared/src/features/session/store/SessionActions'
import { SessionReducer } from 'shared/src/features/session/store/SessionReducers'
import { LoginReducer } from 'shared/src/features/login/store/reducers'
import { ConfigDebugReducer } from 'shared/src/features/debug/store/reducers'
import { NetworkReducer } from 'shared/src/network/store'
import { InviteReducer } from 'shared/src/features/invite/store/InvitePartnerReducers'
import { ExperimentsReducer } from 'shared/src/features/experiments/Experiments'
import { OnboardReducer as SharedOnboardReducer } from 'shared/src/features/onboard/store/OnboardReducer'
import { OnboardReducer } from 'features/onboard/store'
import { OnboardActions } from 'shared/src/features/onboard/store/OnboardActions'
import { BillingReducer as SharedBillingReducer } from 'shared/src/features/billing/store/reducers'
import { BillingReducer } from 'features/billing/store/reducers'
import { PreloginReducer } from 'shared/src/features/prelogin/store'

const appReducer = combineReducers({
  network: NetworkReducer,
  session: SessionReducer,
  config: ConfigDebugReducer,
  login: LoginReducer,
  invite: InviteReducer,
  experiments: ExperimentsReducer,
  onboard: reduceReducers(SharedOnboardReducer, OnboardReducer),
  billing: reduceReducers(SharedBillingReducer, BillingReducer),
  prelogin: PreloginReducer,
})

const retainKeys = ['config', 'network', 'session', 'onboard']

const retainBaseKeys = ['config', 'network', 'session']

const RootReducer = (state, action) => {
  if (action.type === getType(SessionActions.logout) && state) {
    state =
      retainKeys.length > 0
        ? Object.assign({}, ...retainKeys.map((key) => ({ [key]: state[key] })))
        : undefined
  }
  if (action.type === getType(OnboardActions.resetQuestions) && state) {
    state =
      retainBaseKeys.length > 0
        ? Object.assign({}, ...retainBaseKeys.map((key) => ({ [key]: state[key] })))
        : undefined
  }
  return appReducer(state, action)
}

export default RootReducer
