import { get, isNil } from 'lodash/fp'
import { OnboardQuestionSource } from 'features/onboard/screens/OnboardQuestionSource'

const selectOnboard = (state) => get('onboard', state)

export const selectOnboardQuestions = (state) => get('questions', selectOnboard(state))

export const selectIsFirstOnboardQuestion = (state) => {
  const questions = selectOnboardQuestions(state)
  if (isNil(questions)) return null
  return questions.sectionIndex === 0 && questions.questionIndex === 0
}

export const selectIsLastQuestionForOnboard = (state) => {
  const questions = selectOnboardQuestions(state)
  if (isNil(questions)) return false

  const { sections, sectionIndex, questionIndex } = questions
  const lastQuestionOffset = questions.source == OnboardQuestionSource.retake ? 2 : 1

  if (
    sectionIndex === sections.length - 1 &&
    questionIndex === sections[sections.length - 1].pages.length - lastQuestionOffset
  ) {
    return true
  } else {
    return false
  }
}

export const selectOnboardQuestionsSections = (state) =>
  get('sections', selectOnboardQuestions(state))
