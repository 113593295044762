const animations = {
  invite_sent: require('./invite_sent.json'),
  retake_warning_submit: require('./retake_warning_submit.json'),
  retake_changes_lost: require('./retake_changes_lost.json'),
  confetti: require('./confetti.json'),
  sun_moon: require('./sun_moon.json'),
  onboard_goal: require('./onboard_goal.json'),
  calendar: require('./calendar.json'),
  happy: require('./happy.json'),
  comfort: require('./comfort.json'),
  rewarding: require('./rewarding.json'),
  scale_slider: require('./scale_slider.json'),
  loading_spinner: require('./loading_spinner.json'),
  bouncing_arrow: require('./bouncing_arrow.json'),
  partner_heart: require('./partner_heart.json'),
}

export default animations
