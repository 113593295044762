import analytics from 'shared/src/data/analytics'
import { pick, reduce } from 'lodash/fp'

const queue = new Set()

const addToQueue = (metric) => queue.add(pick(['name', 'value'], metric))

const reportWebVitals = async (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals')
    getCLS(onPerfEntry)
    getFID(onPerfEntry)
    getFCP(onPerfEntry)
    getLCP(onPerfEntry)
    getTTFB(onPerfEntry)
  }
}

const flushQueue = () => {
  if (queue.size > 0) {
    analytics.logEvent(
      'Web Onboarding Performance',
      reduce(
        (obj, curr) => {
          obj[curr['name']] = curr['value']
          return obj
        },
        {},
        [...queue]
      )
    )
    queue.clear()
  }
}

export const init = () => {
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue()
    }
  })
  addEventListener('pagehide', flushQueue)
  reportWebVitals(addToQueue)
}
