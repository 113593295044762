import { combineEpics } from 'redux-observable'
import { SessionEpicFactory } from 'shared/src/features/session/store/SessionEpics'
import { LoginEpicFactory as SharedLoginEpicFactory } from 'shared/src/features/login/store/epics'
import { LoginEpicFactory } from 'features/login/store/epics'
import { InvitePartnerEpicFactory as SharedInvitePartnerEpicFactory } from 'shared/src/features/invite/store/InvitePartnerEpics'
import { InvitePartnerEpicFactory } from 'features/invite/store/InvitePartnerEpics'
import { ExperimentEpicFactory } from 'shared/src/features/experiments/Experiments'
import { OnboardEpicFactory as SharedOnboardEpicFactory } from 'shared/src/features/onboard/store/OnboardEpics'
import { OnboardEpicFactory } from 'features/onboard/store'
import { BillingEpicFactory } from 'features/billing/store/epics'
import { BillingEpicFactory as SharedBillingEpicFactory } from 'shared/src/features/billing/store/epics'
import { PreloginEpicFactory } from 'shared/src/features/prelogin/store'

const RootEpic = (networkClient) =>
  combineEpics(
    SharedLoginEpicFactory(networkClient),
    LoginEpicFactory(),
    SessionEpicFactory(networkClient),
    InvitePartnerEpicFactory(networkClient),
    ExperimentEpicFactory(networkClient),
    SharedInvitePartnerEpicFactory(networkClient),
    SharedOnboardEpicFactory(networkClient),
    OnboardEpicFactory(networkClient),
    BillingEpicFactory(networkClient),
    SharedBillingEpicFactory(networkClient),
    PreloginEpicFactory(networkClient)
  )

export default RootEpic
