import { createAsyncAction, createAction } from 'typesafe-actions'

export const LoginActions = {
  requestMagicLink: createAsyncAction(
    'login/POST_REQUEST_MAGIC_LINK/REQUEST',
    'login/POST_REQUEST_MAGIC_LINK/SUCCESS',
    'login/POST_REQUEST_MAGIC_LINK/FAILURE'
  )(),
  magicLogin: createAsyncAction(
    'login/POST_MAGIC_LOGIN/REQUEST',
    'login/POST_MAGIC_LOGIN/SUCCESS',
    'login/POST_MAGIC_LOGIN/FAILURE'
  )(),
  magicLoginReset: createAction('login/POST_MAGIC_LOGIN/RESET')(),
  completeMagicMarketingRegistration: createAction('login/COMPLETE_MAGIC_MARKETING_REGISTRATION')(),
}
