import { getType } from 'typesafe-actions'
import { ofType } from 'redux-observable'
import { merge } from 'lodash/fp'
import { pipe, of } from 'rxjs'
import { map, flatMap, catchError, filter } from 'rxjs/operators'

const userIdReplaceString = ':userid'

export const nextPageEpic = (type, selector, request) => (action$, state$) =>
  action$.pipe(
    ofType(type),
    map(() => selector(state$.value)),
    filter(({ fetching }) => !fetching),
    map(({ paging }) => paging),
    filter(({ lastPage, currentPage }) => lastPage != currentPage),
    map(({ currentPage }) => request(state$, currentPage))
  )

export const requestInProgressReducer = (asyncAction) => (state = false, action) => {
  switch (action.type) {
    case getType(asyncAction.request):
      return true
    case getType(asyncAction.success):
      return false
    case getType(asyncAction.failure):
      return false
    default:
      return state
  }
}

export const pagedEndpointReducer = (reloadAction, requestAsyncAction) => (state = {}, action) => {
  switch (action.type) {
    case getType(reloadAction):
      return { pages: [], lastPage: 0, currentPage: 0, showFetching: true }
    case getType(requestAsyncAction.success):
      switch (action.payload.requestStatus) {
        case 200:
          return {
            pages: merge(state.pages, { [action.payload.thisPage]: action.payload.order }),
            lastPage: action.payload.lastPage,
            currentPage: action.payload.thisPage,
            showFetching: false,
          }
        default:
          return { ...state, showFetching: false }
      }
    case getType(requestAsyncAction.failure):
      return { ...state, showFetching: false }
    default:
      return state
  }
}

export const fetchEpic = (networkClient, asyncType, pathFromPayload) => (action$, state$) =>
  action$.pipe(
    ofType(getType(asyncType.request)),
    flatMap((requestAction) =>
      networkClient
        .fetch(
          state$.value.session.userToken,
          state$.value.config.apiHost,
          pathFromPayload(requestAction.payload, state$.value).replace(
            userIdReplaceString,
            state$.value.session.userId
          )
        )
        .pipe(
          map(({ status, response }) =>
            asyncType.success({ ...response, requestStatus: status, requestAction: requestAction })
          ),
          catchError(
            pipe((error) => asyncType.failure({ ...error, requestAction: requestAction }), of)
          )
        )
    )
  )

export const unAuthFetchEpic = (networkClient, asyncType, pathFromPayload) => (action$, state$) =>
  action$.pipe(
    ofType(getType(asyncType.request)),
    flatMap((requestAction) =>
      networkClient
        .unAuthFetch(
          state$.value.config.apiHost,
          pathFromPayload(requestAction.payload).replace(
            userIdReplaceString,
            state$.value.session.userId
          )
        )
        .pipe(
          map(({ status, response }) =>
            asyncType.success({
              ...response,
              requestStatus: status,
              requestAction: requestAction,
            })
          ),
          catchError(
            pipe(
              (error) =>
                asyncType.failure({
                  ...error,
                  requestAction: requestAction,
                }),
              of
            )
          )
        )
    )
  )

export const postEpic = (networkClient, asyncType, pathFromPayload, bodyFromPayload = (x) => x) => (
  action$,
  state$
) =>
  action$.pipe(
    ofType(getType(asyncType.request)),
    flatMap((requestAction) => {
      return networkClient
        .post(
          state$.value.session.userToken,
          state$.value.config.apiHost,
          pathFromPayload(requestAction.payload, state$.value).replace(
            userIdReplaceString,
            state$.value.session.userId
          ),
          bodyFromPayload(requestAction.payload, state$.value)
        )
        .pipe(
          map((response) => asyncType.success({ ...response, requestAction: requestAction })),
          catchError(
            pipe((error) => asyncType.failure({ ...error, requestAction: requestAction }), of)
          )
        )
    })
  )
export const deleteEpic = (networkClient, asyncType, pathFromPayload) => (action$, state$) =>
  action$.pipe(
    ofType(getType(asyncType.request)),
    flatMap((requestAction) =>
      networkClient
        .delete(
          state$.value.session.userToken,
          state$.value.config.apiHost,
          pathFromPayload(requestAction.payload).replace(
            userIdReplaceString,
            state$.value.session.userId
          )
        )
        .pipe(
          map((x) => asyncType.success({ ...x.response, requestAction: requestAction })),
          catchError(
            pipe((error) => asyncType.failure({ ...error, requestAction: requestAction }), of)
          )
        )
    )
  )
