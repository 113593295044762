import RootEpic from './RootEpic'
import RootReducer from './RootReducer'
import { makeStore } from 'shared/src/data/store'

const networkEvents = {}

export const configureStore = (networkConfig) => {
  const store = makeStore({
    networkConfig,
    networkEvents,
    rootReducer: RootReducer,
    rootEpic: RootEpic,
  })

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./RootReducer', () => {
      const nextRootReducer = require('./RootReducer').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
