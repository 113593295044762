import { REHYDRATE } from 'redux-persist'
import { ConfigDebugActions } from './actions'
import { getType } from 'typesafe-actions'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistReducer } from 'utils/ReduxUtils'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const initialState = {
  apiHost: __DEV__ ? 'http://localhost:8080' : 'https://hellorelish.herokuapp.com',
}

export const DEBUG_REDUCER_PERSIST_CONFIG_KEY = 'config'

const DebugReducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.config) {
        return {
          ...state,
          apiHost: action.payload.apiHost,
        }
      } else {
        return state
      }
    case getType(ConfigDebugActions.ApiHostUpdated):
      return {
        ...state,
        apiHost: action.payload.apiHost,
      }
    default:
      return state
  }
}

const configDebugPersistConfig = {
  key: DEBUG_REDUCER_PERSIST_CONFIG_KEY,
  storage: AsyncStorage,
  debug: true,
  timeout: null,
  stateReconciler: autoMergeLevel2,
}

export const ConfigDebugReducer = persistReducer(configDebugPersistConfig, DebugReducer)
