import colors from './colors'
import fontStyles from './fontStyles'
import { Platform, StyleSheet } from 'react-native'

const shadow = {
  shadowColor: colors.shadowColorLighter,
  shadowOpacity: 1,
  shadowRadius: 12,
  shadowOffset: {
    width: 0,
    height: 0,
  },
  elevation: 6,
}

const buttonShadow = {
  shadowColor: colors.black_50_percent,
  shadowOpacity: 0.4,
  shadowRadius: 0.8,
  shadowOffset: {
    width: 0,
    height: 1,
  },
  elevation: 1,
}

const topShadow = {
  shadowColor: colors.black_50_percent,
  shadowRadius: 0.8,
  shadowOpacity: 0.2,
  shadowOffset: {
    width: 0,
    height: -1,
  },
  elevation: 8,
  backgroundColor: colors.white,
}

export const styles = {
  cardShadow: {
    elevation: 2,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
  },
  bottomShadow: {
    backgroundColor: colors.white,
    shadowColor: colors.black_50_percent,
    shadowRadius: 0.8,
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 8,
  },
  container: {
    flex: 1,
    backgroundColor: colors.yellow_light,
  },
  card: {
    margin: 16,
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
    ...shadow,
  },
  newCard: {
    margin: 16,
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
  },
  newCardShadow: {
    margin: 16,
    backgroundColor: colors.white,
    borderRadius: 2,
    padding: 16,
    shadowColor: colors.midnight,
    shadowOpacity: 0.5,
    shadowRadius: 1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  courseCard: {
    width: 160,
    height: 244,
    borderRadius: 6,
    backgroundColor: colors.white,
    margin: 8,
  },
  defaultMargin: {
    marginLeft: 16,
    marginRight: 16,
  },
  shadow: { ...shadow },
  topShadow: { ...topShadow },
  buttonShadow: { ...buttonShadow },
  stickyButtonShadow: {
    shadowColor: colors.black_50_percent,
    shadowRadius: 6,
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: -4,
    },
    elevation: 8,
  },
  separatorLine: {
    height: StyleSheet.hairlineWidth,
    width: '100%',
    backgroundColor: colors.separator,
  },
  navHeaderLeft: {
    height: '100%',
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navHeaderLeftIcon: {
    padding: 8,
    paddingBottom: 12,
  },
  navHeaderRightIcon: {
    marginRight: 8,
    padding: 4,
    paddingBottom: 8,
  },
  navHeaderTitle: {
    marginLeft: Platform.OS == 'ios' ? 24 : 0,
    marginRight: 24,
    ...fontStyles.old_pageTitle,
  },
}

export default styles
