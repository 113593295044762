import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

import languageEn from 'assets/i18n/en'

const resources = {
  en: languageEn,
}

i18n.use(reactI18nextModule).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})
