import fonts from './fonts'
import colors from './colors'
import { normalizeSize } from 'utils/ScreenUtils'
import { Platform } from 'react-native'

const fontStyles = {
  extralight: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: '100',
  },
  light: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: '200',
  },
  h1: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(32),
    lineHeight: normalizeSize(32) * 1.2,
    letterSpacing: -0.02,
    color: colors.greyishBrown,
  },
  h2: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(24),
    lineHeight: normalizeSize(24) * 1.3,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  h3: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(19),
    lineHeight: normalizeSize(19) * 1.3,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  h4: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(17) * 1.2,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  h5: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(17) * 1.4,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  h6: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(15),
    lineHeight: normalizeSize(15) * 1.4,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  bigNumber: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 28,
    letterSpacing: 0.4,
  },
  body: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(16),
    lineHeight: normalizeSize(16) * 1.6,
    letterSpacing: 0,
    color: colors.greyishBrown,
  },
  caption: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(14),
    lineHeight: normalizeSize(14) * 1.4,
    letterSpacing: -0.01,
    color: colors.warmGrey,
  },
  subtitle: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(12),
    lineHeight: normalizeSize(12) * 1.4,
    letterSpacing: -0.01,
    color: colors.warmGrey,
  },
  slideTitle: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(20),
    lineHeight: normalizeSize(20) * 1.2,
    color: colors.midnight,
    letterSpacing: -0.2,
  },
  labelLarge: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(17) * 1.4,
    letterSpacing: -0.01,
    color: colors.midnight,
  },
  labelMedium: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: '600',
    fontSize: normalizeSize(14),
    lineHeight: normalizeSize(14) * 1.4,
    letterSpacing: -0.01,
    color: colors.greyishBrown,
  },
  labelSmall: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(10),
    lineHeight: 15,
    letterSpacing: 0.3,
    color: colors.greyishBrown,
  },
  textLink: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: '600',
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(17) * 1.6,
    letterSpacing: -0.01,
    color: colors.yellow,
  },
  textInput: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: 'normal', // https://github.com/facebook/react-native/issues/18820#issuecomment-481693150
    fontSize: normalizeSize(16),
    letterSpacing: -0.01,
    color: colors.warmGreyDark,
    padding: 0, // Removes default padding on Android, does not affect iOS
  },
  modalTextInput: {
    fontFamily: fonts.dmsans.regular,
    fontWeight: 'normal', // https://github.com/facebook/react-native/issues/18820#issuecomment-481693150
    fontSize: normalizeSize(16),
    lineHeight: normalizeSize(16) * 1.6,
    letterSpacing: -0.01,
    color: colors.warmGreyDark,
    padding: 0, // Removes default padding on Android, does not affect iOS
  },
  // new fontStyles below - used in new lesson
  newh1: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(46),
    lineHeight: normalizeSize(46) * 1.1,
    letterSpacing: -0.8,
    color: colors.midnight,
  },
  newh2: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(32),
    lineHeight: normalizeSize(32) * 1.1,
    letterSpacing: -0.2,
    color: colors.midnight,
  },
  newh3: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(26),
    lineHeight: normalizeSize(26) * 1.2,
    letterSpacing: -0.01,
    color: colors.midnight,
  },
  newh4: {
    fontFamily: fonts.dmserif.regular,
    fontSize: normalizeSize(21),
    lineHeight: normalizeSize(21) * 1.3,
    letterSpacing: -0.01,
    color: colors.midnight,
  },
  newh5: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(17) * 1.3,
    letterSpacing: -0.01,
    color: colors.midnight,
  },
  newbody: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(17),
    lineHeight: normalizeSize(18) * 1.2,
    letterSpacing: -0.2,
    color: colors.greyishBrown,
  },
  newbodyBold: {
    fontFamily: fonts.dmsans.bold,
    fontSize: normalizeSize(16),
    lineHeight: normalizeSize(16) * 1.4,
    color: colors.midnight,
    letterSpacing: -0.2,
  },
  newBigBoldBody: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(21),
    lineHeight: normalizeSize(21) * 1.3,
    color: colors.midnight,
    letterSpacing: -0.2,
  },
  newsubtitle: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(12),
    lineHeight: normalizeSize(12) * 1.4,
    color: colors.warmGrey,
  },
  newcaption: {
    fontFamily: fonts.dmsans.regular,
    fontSize: normalizeSize(14),
    lineHeight: normalizeSize(14) * 1.4,
    color: colors.greyishBrown,
  },
  legacySignpost: {
    fontFamily: fonts.dmsans.bold,
    fontSize: normalizeSize(12),
    lineHeight: normalizeSize(12) * 1.4,
    letterSpacing: 1,
    color: colors.greyishBrown,
    textTransform: 'uppercase',
  },
  newcomment: {
    fontFamily: fonts.dmsans.bold,
    fontSize: normalizeSize(12),
    lineHeight: normalizeSize(12) * 1.2,
    color: colors.midnight,
  },
  // *** Deprecated fontstyles below -  do not use - being phased out ***
  old_headline3: {
    fontFamily: fonts.dmserif.regular,
    fontSize: 38,
    lineHeight: 56,
    letterSpacing: 0.1,
    color: colors.dark,
  },
  old_headline5: {
    fontFamily: fonts.dmserif.regular,
    fontSize: 26,
    lineHeight: 32,
    letterSpacing: 0.2,
    color: colors.dark,
  },
  old_headline55: {
    fontFamily: fonts.dmserif.regular,
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: 0.3,
    color: colors.dark,
  },
  old_headline6: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: 0.4,
    color: colors.dark,
  },
  old_pageTitle: {
    fontFamily: fonts.dmserif.regular,
    fontSize: 19,
    color: colors.grey_dark,
  },
  old_textInput: {
    fontFamily: fonts.dmsans.medium,
    fontWeight: 'normal', // https://github.com/facebook/react-native/issues/18820#issuecomment-481693150
    fontSize: 16,
    letterSpacing: 0.4,
    color: colors.dark,
    padding: 0, // Removes default padding on Android, does not affect iOS
  },
  old_subtitle2: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: Platform.OS === 'ios' ? 0.4 : 0.2,
    color: colors.dark,
  },
  old_subtitle3: {
    fontFamily: fonts.dmserif.regular,
    fontSize: 16,
    lineHeight: 23,
    letterSpacing: 0.15,
    color: '#555b6d',
  },
  old_button: {
    fontFamily: fonts.dmsans.medium,
    fontSize: normalizeSize(15),
    lineHeight: normalizeSize(18),
    letterSpacing: 0.4,
    color: colors.dark,
  },
  old_body2: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.4,
    color: colors.dark,
  },
  old_overline: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 13,
    lineHeight: 16,
    letterSpacing: 0.4,
    color: colors.dark,
    textTransform: 'uppercase',
  },
  old_caption: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 12,
    lineHeight: 20,
    letterSpacing: 0.4,
    color: colors.dark,
  },
  old_xscaption: {
    fontFamily: fonts.dmsans.medium,
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.25,
    color: colors.black_25_percent,
  },
  xxxlarge: {
    fontFamily: fonts.modernEra.extraBold,
    fontSize: normalizeSize(54),
    lineHeight: normalizeSize(54) * 1.05,
    letterSpacing: -1,
    color: colors.black,
  },
  xxlarge: {
    fontFamily: fonts.modernEra.bold,
    fontSize: normalizeSize(44),
    lineHeight: normalizeSize(44) * 1.2,
    letterSpacing: -0.5,
    color: colors.black,
  },
  xlarge: {
    fontFamily: fonts.modernEra.bold,
    fontSize: normalizeSize(35),
    lineHeight: normalizeSize(35) * 1.2,
    letterSpacing: -0.5,
    color: colors.black,
  },
  large: {
    fontFamily: fonts.modernEra.bold,
    fontSize: normalizeSize(28),
    lineHeight: normalizeSize(28) * 1.3,
    letterSpacing: -0.5,
    color: colors.black,
  },
  medium: {
    fontFamily: fonts.modernEra.medium,
    fontSize: normalizeSize(26),
    lineHeight: normalizeSize(26) * 1.3,
    letterSpacing: -0.5,
    color: colors.black,
  },
  small: {
    fontFamily: fonts.modernEra.medium,
    fontSize: normalizeSize(21),
    lineHeight: normalizeSize(21) * 1.3,
    letterSpacing: -0.5,
    color: colors.black,
  },
  xsmall: {
    fontFamily: fonts.modernEra.regular,
    fontSize: normalizeSize(14),
    lineHeight: normalizeSize(14) * 1.3,
    letterSpacing: -0.01,
    color: colors.black,
  },
  regularBody: {
    fontFamily: fonts.modernEra.regular,
    fontSize: normalizeSize(18),
    lineHeight: normalizeSize(18) * 1.4,
    color: colors.black,
  },
  largeBody: {
    fontFamily: fonts.modernEra.regular,
    fontSize: normalizeSize(21),
    lineHeight: normalizeSize(21) * 1.4,
    letterSpacing: -0.2,
    color: colors.black,
  },
  input: {
    fontFamily: fonts.modernEra.regular,
    fontSize: normalizeSize(18),
    lineHeight: normalizeSize(18) * 1.2,
    color: colors.black,
  },
  cta: {
    fontFamily: fonts.modernEra.bold,
    fontSize: normalizeSize(18),
    lineHeight: normalizeSize(18) * 1.2,
    color: colors.black,
  },
  signpost: {
    fontFamily: fonts.modernEra.medium,
    fontSize: normalizeSize(14),
    lineHeight: normalizeSize(14) * 1.4,
    letterSpacing: 2,
    color: colors.black,
    textTransform: 'uppercase',
  },
  footnote: {
    fontFamily: fonts.modernEra.regular,
    fontSize: normalizeSize(12),
    lineHeight: normalizeSize(12) * 1.2,
    color: colors.black,
  },
}

export default fontStyles
