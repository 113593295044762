const constants = {
  url: {
    terms: 'https://hellorelish.com/terms-of-use-app/',
    privacy: 'https://hellorelish.com/privacy-policy-app/',
  },
  DEBUG_EMAIL: 'debug@hellorelish.com',
  APPLE_TEST_USERS: {
    'flyflyerson@hellorelish.com': {
      userid: 17,
      login_token: '00000177602bfcdd098c0131fb8b5b2f922f9002f7ce5b91e77a7162c3eb9831',
      profile_complete: true,
      email: 'flyflyerson@hellorelish.com',
    },
    'subflyflyerson@hellorelish.com': {
      userid: 4668,
      login_token: '0000017279fea962ad87ca18f4c769629bf604f261dbd0bdd75d3e0b08ea947a',
      profile_complete: true,
      email: 'subflyflyerson@hellorelish.com',
    },
  },
}

export default constants
