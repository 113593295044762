import { createAction, createAsyncAction } from 'typesafe-actions'

const SessionActions = {
  appLaunch: createAction('session/APP_LAUNCH')(),
  setSession: createAction('session/SET_SESSION')(),
  setProfileComplete: createAction('session/SET_SESSION_PROFILE_COMPLETE')(),
  deleteAccountAndLogout: createAsyncAction(
    'session/DELETE_ACCOUNT_AND_LOGOUT/REQUEST',
    'session/DELETE_ACCOUNT_AND_LOGOUT/SUCCESS',
    'session/DELETE_ACCOUNT_AND_LOGOUT/FAILURE'
  )(),
  logout: createAction('session/LOG_OUT')(),
}

export default SessionActions
