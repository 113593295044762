import { Platform } from 'react-native'
import { getUniqueId } from 'react-native-device-info'
import { ajax } from 'rxjs/ajax'
import { omitBy, isNil } from 'lodash/fp'
import { WEB_UNIQUE_ID } from 'features/experiments/Experiments'

const getDeviceId = () =>
  Platform.OS === 'web' ? window.localStorage.getItem(WEB_UNIQUE_ID) : getUniqueId()

const omitNilValues = omitBy(isNil)

const client = (config) => ({
  unAuthFetch: (baseUrl, url) =>
    ajax.get(
      `${baseUrl}${url}`,
      omitNilValues({
        Authorization: config.Authorization,
        'client-version': config.appVersion,
        'client-platform': Platform.OS,
        'client-country': config.country,
        'client-device-id': getDeviceId(),
        'client-attribution-id': config.attributionId,
        'client-utm': config.utm,
        'client-fbc': config.fbc,
        'client-gclid': config.gclid,
      })
    ),
  fetch: (userToken, baseUrl, url) =>
    ajax.get(
      `${baseUrl}${url}`,
      omitNilValues({
        Authorization: config.Authorization,
        'client-version': config.appVersion,
        'client-platform': Platform.OS,
        'client-country': config.country,
        'client-device-id': getDeviceId(),
        'client-attribution-id': config.attributionId,
        'client-utm': config.utm,
        'client-fbc': config.fbc,
        'client-gclid': config.gclid,
        ...xAuthHeader(userToken),
      })
    ),
  post: (userToken, baseUrl, url, body) => {
    return ajax.post(
      `${baseUrl}${url}`,
      JSON.stringify(body),
      omitNilValues({
        Authorization: config.Authorization,
        'client-version': config.appVersion,
        'client-platform': Platform.OS,
        'client-country': config.country,
        'client-device-id': getDeviceId(),
        'Content-Type': 'application/json',
        'client-attribution-id': config.attributionId,
        'client-utm': config.utm,
        'client-fbc': config.fbc,
        'client-gclid': config.gclid,
        ...xAuthHeader(userToken),
      })
    )
  },
  delete: (userToken, baseUrl, url) =>
    ajax.delete(
      `${baseUrl}${url}`,
      omitNilValues({
        Authorization: config.Authorization,
        'client-version': config.appVersion,
        'client-platform': Platform.OS,
        'client-country': config.country,
        'client-device-id': getDeviceId(),
        'client-attribution-id': config.attributionId,
        'client-utm': config.utm,
        'client-fbc': config.fbc,
        'client-gclid': config.gclid,
        ...xAuthHeader(userToken),
      })
    ),
})

export const xAuthHeader = (userToken) => {
  if (userToken == null || userToken.trim().length == 0) {
    return {}
  } else {
    return { 'X-Auth': `token ${userToken}` }
  }
}

export default client
