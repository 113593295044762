import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NetInfo from '@react-native-community/netinfo'
import { NetworkActions } from './store'

const Connectivity = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((e) => {
      dispatch(NetworkActions.connectivityUpdate(e))
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return <></>
}

export default Connectivity
