import { createAsyncAction, createAction } from 'typesafe-actions'

export const BillingActions = {
  beginPurchase: createAction('billing/BEGIN_PURCHASE')(),
  fetchPaywall: createAsyncAction(
    'billing/FETCH_PAYWALL/REQUEST',
    'billing/FETCH_PAYWALL/RECIEVE_SUCCESS',
    'billing/FETCH_PAYWALL/RECIEVE_FAILURE'
  )(),
  upsellAction: createAction('billing/UPSELL_ACTION')(),
  delayUpsellAction: createAction('billing/DELAY_UPSELL_ACTION')(),
  verifyPurchases: createAction('billing/VERIFY_IF_NOT_ALREADY')(),
  verifyPurchasesRequest: createAsyncAction(
    'billing/VERIFY/REQUEST',
    'billing/VERIFY/RECIEVE_SUCCESS',
    'billing/VERIFY/RECIEVE_FAILURE'
  )(),
  presentPaywall: createAction('billing/PRESENT_PAYWALL')(),
  purchaseComplete: createAction('billing/PURCHASE_COMPLETE')(),
  createCheckoutSession: createAsyncAction(
    'billing/CREATE_CHECKOUT_SESSION/REQUEST',
    'billing/CREATE_CHECKOUT_SESSION/RECEIVE_SUCCESS',
    'billing/CREATE_CHECKOUT_SESSION/RECEIVE_FAILURE'
  )(),
}
