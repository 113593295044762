import { Dimensions, Platform, PixelRatio } from 'react-native'

export const WEB_MAX_WIDTH = 500
export const WEB_MAX_HEIGHT = 900

// Get the dimensions of the app.
// On mobile, this will be the dimensions of the window as the app always fills the window.
// However on web, we letterbox the app to a specific max width and height, so we need to use those dimensions when doing calculations
// Note that at the moment these values won't update as the user adjusts the viewport.
// This would require us to refactor where we use these dimensions as we would have to make sure we do these in renders.
export const appDimensions = () => {
  const { width, height, ...rest } = Dimensions.get('window')
  return Platform.OS === 'web'
    ? { ...rest, width: Math.min(width, WEB_MAX_WIDTH), height: Math.min(height, WEB_MAX_HEIGHT) }
    : { ...rest, width, height }
}

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = appDimensions()

// based on iphone X's scale
const scale = SCREEN_WIDTH / 375

const verticalScale = SCREEN_HEIGHT / 812

const normalize = (size, scale) => {
  const newSize = size * scale
  return Math.round(PixelRatio.roundToNearestPixel(newSize))
}

export const normalizeSize = (size) => {
  return normalize(size, scale)
}

export const normalizeVertical = (size) => {
  return normalize(size, verticalScale)
}

export const iPhoneSE = () => {
  return SCREEN_WIDTH <= 320
}
