import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import colors from 'assets/colors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const RelishLoadingIndicator = ({ style, size = 'large', spinnerColor = colors.yellow }) => (
  <View style={[styles.container, style]}>
    <ActivityIndicator size={size} color={spinnerColor} />
  </View>
)

export default RelishLoadingIndicator
