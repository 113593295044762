import { interval } from 'rxjs'
import { map, delayWhen, filter, delay, tap, ignoreElements } from 'rxjs/operators'
import { isEqual } from 'lodash/fp'
import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import analytics from 'data/analytics'
import { fetchEpic, postEpic } from 'data/network'
import { OnboardActions } from './OnboardActions'
import { selectOnboardQuestionsSections } from './OnboardSelectors'
import { OnboardQuestionSource } from 'features/onboard/screens/OnboardQuestionSource'
import marketing from 'features/marketing'

const onboardPageTypes = (sections) =>
  sections.flatMap(({ pages }) => pages.map(({ type }) => type))

const isSameOnboardPages = (sections, newSections) =>
  isEqual(onboardPageTypes(sections), onboardPageTypes(newSections))

const CommitNewSectionsIfTheyDifferFromCurrentSections = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.fetchSections.success)),
    filter(
      ({ payload }) =>
        !isSameOnboardPages(selectOnboardQuestionsSections(state$.value), payload.sections)
    ),
    tap(() => analytics.logEvent('Onboarding Questions Downloaded')),
    map(({ payload }) => OnboardActions.commitNewSections(payload))
  )

const BackoffRetryFetchSectionsOnFailure = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.fetchSections.failure)),
    delayWhen(() => interval(state$.value.onboard.fetchSections.delay)),
    map(() => OnboardActions.fetchSections.request())
  )

const ShowGeneratingNewInsightsWhenRequestPostProfileWithRestartPlan = (action$) =>
  action$.pipe(
    ofType(getType(OnboardActions.requestPostProfile.request)),
    filter(({ payload }) => payload.restartPlan && payload.fetchInsights),
    map(() => OnboardActions.generatingNewInsights())
  )

const ShowNewInsightsAfterADelay = (action$) =>
  action$.pipe(
    ofType(getType(OnboardActions.generatingNewInsights)),
    delay(4500),
    map(() => OnboardActions.showNewInsights())
  )

export const CompleteMarketingRegistrationOnEmailCollection = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.collect)),
    filter(() => !isEqual(state$.value.onboard.questions.source, OnboardQuestionSource.retake)),
    filter(({ payload }) => payload.collectKey === 'email'),
    tap(() => marketing.completeRegistration(state$.value.session.userId)),
    ignoreElements()
  )

export const UpdateIdentity = (action$, state$) =>
  action$.pipe(
    ofType(getType(OnboardActions.collect)),
    filter(
      ({ payload }) =>
        payload.collectKey === 'email' ||
        payload.collectKey === 'window_time_mins' ||
        payload.collectKey === 'timezone'
    ),
    map(() =>
      OnboardActions.requestUpdateIdentity.request({
        ...state$.value.onboard.questions.collected,
      })
    )
  )

export const OnboardEpicFactory = (networkClient) =>
  combineEpics(
    postEpic(
      networkClient,
      OnboardActions.requestPostProfile,
      () => `/user-profile/:userid`,
      (payload) => payload
    ),
    postEpic(
      networkClient,
      OnboardActions.requestUpdateIdentity,
      () => `/user-identity/:userid/update`,
      (payload) => payload
    ),
    fetchEpic(networkClient, OnboardActions.fetchSections, () => `/v3/onboarding-sections/:userid`),
    BackoffRetryFetchSectionsOnFailure,
    ShowGeneratingNewInsightsWhenRequestPostProfileWithRestartPlan,
    ShowNewInsightsAfterADelay,
    CommitNewSectionsIfTheyDifferFromCurrentSections,
    CompleteMarketingRegistrationOnEmailCollection,
    UpdateIdentity
  )
