import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import { postEpic } from 'data/network'
import { LoginActions } from './actions'
import NavigationService from 'features/navigation/NavigationService'
import { map, tap, ignoreElements, filter } from 'rxjs/operators'
import analytics from 'data/analytics'
import marketing from 'features/marketing'
import { getTimeZone } from 'react-native-localize'
import SessionActions from 'features/session/store/SessionActions'
import { InviteActions } from 'features/invite/store/actions'
import Braze from 'data/braze'

export const magicLoginRequestAnalytics = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.request)),
    tap(() => {
      analytics.logEvent('Magic login - request')
      Braze.logCustomEvent('Magic login - request')
    }),
    ignoreElements()
  )

export const storePartnerConnectionTokenAfterSuccessfulMagicLogin = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.success)),
    filter(({ payload }) => payload.response.partnerConnectionToken),
    map(({ payload }) =>
      InviteActions.storePartnerConnectionToken(payload.response.partnerConnectionToken)
    )
  )

export const setSessionAfterSuccessfulMagicLogin = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.success)),
    tap(() => {
      analytics.logEvent('Magic login - success')
      Braze.logCustomEvent('Magic login - success')
    }),
    map(({ payload }) => SessionActions.setSession(payload.response))
  )

const errorScreenAfterFailedMagicLogin = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.failure)),
    tap(() => {
      NavigationService.navigate('TokenAuthError')
      analytics.logEvent('Magic login - failed')
    }),
    ignoreElements()
  )

export const marketingLoginOnMagicLoginSuccess = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.magicLogin.success)),
    tap(({ payload }) => marketing.login(payload.response.userid)),
    map(({ payload }) => LoginActions.completeMagicMarketingRegistration(payload))
  )

export const completeMarketingRegistrationOnMagicLoginSuccess = (action$) =>
  action$.pipe(
    ofType(getType(LoginActions.completeMagicMarketingRegistration)),
    filter(({ payload }) => !payload.response.profileComplete),
    tap(({ payload }) => marketing.completeRegistration(payload.response.userid)),
    ignoreElements()
  )

export const LoginEpicFactory = (networkClient) =>
  combineEpics(
    postEpic(
      networkClient,
      LoginActions.requestMagicLink,
      () => '/magic-auth/request-token',
      ({ email, partnerConnectionToken }) => ({ email, partnerConnectionToken })
    ),
    postEpic(
      networkClient,
      LoginActions.magicLogin,
      () => '/magic-auth/login',
      ({ token }) => ({ token: token, timezone: getTimeZone() })
    ),
    // We need to store the partnerConnectionToken before setting session
    // so that we navigate to the TokenPartnerConnection screen to complete partnering up
    storePartnerConnectionTokenAfterSuccessfulMagicLogin,
    setSessionAfterSuccessfulMagicLogin,
    errorScreenAfterFailedMagicLogin,
    magicLoginRequestAnalytics,
    completeMarketingRegistrationOnMagicLoginSuccess,
    marketingLoginOnMagicLoginSuccess
  )
