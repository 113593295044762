const images = {
  left_hand: require('./left_hand/left_hand.png'),
  right_hand: require('./right_hand/right_hand.png'),
  hold_hands: require('./hold_hands/hold_hands.png'),
  postage_stamp: require('./postage_stamp/postage_stamp.png'),
  cat: require('./cat/cat.png'),
  cat_head: require('./cat_head/cat_head.png'),
  cat_cat: require('./cat_cat/cat_cat.png'),
  cat_cockatoo: require('./cat_cockatoo/cat_cockatoo.png'),
  cat_dog: require('./cat_dog/cat_dog.png'),
  cat_rabbit: require('./cat_rabbit/cat_rabbit.png'),
  cockatoo: require('./cockatoo/cockatoo.png'),
  cockatoo_head: require('./cockatoo_head/cockatoo_head.png'),
  cockatoo_cat: require('./cockatoo_cat/cockatoo_cat.png'),
  cockatoo_cockatoo: require('./cockatoo_cockatoo/cockatoo_cockatoo.png'),
  cockatoo_dog: require('./cockatoo_dog/cockatoo_dog.png'),
  cockatoo_rabbit: require('./cockatoo_rabbit/cockatoo_rabbit.png'),
  dog: require('./dog/dog.png'),
  dog_head: require('./dog_head/dog_head.png'),
  dog_cat: require('./dog_cat/dog_cat.png'),
  dog_cockatoo: require('./dog_cockatoo/dog_cockatoo.png'),
  dog_dog: require('./dog_dog/dog_dog.png'),
  dog_rabbit: require('./dog_rabbit/dog_rabbit.png'),
  rabbit: require('./rabbit/rabbit.png'),
  rabbit_head: require('./rabbit_head/rabbit_head.png'),
  rabbit_cat: require('./rabbit_cat/rabbit_cat.png'),
  rabbit_cockatoo: require('./rabbit_cockatoo/rabbit_cockatoo.png'),
  rabbit_dog: require('./rabbit_dog/rabbit_dog.png'),
  rabbit_rabbit: require('./rabbit_rabbit/rabbit_rabbit.png'),
  teeny_logo: require('./teeny_logo/teeny_logo.png'),
  'affection-sex': require('./affection-sex/affection-sex.png'),
  back: require('./back/back.png'),
  'being-grateful': require('./being-grateful/being-grateful.png'),
  communicating: require('./communicating/communicating.png'),
  complimenting: require('./complimenting/complimenting.png'),
  'family-life': require('./family-life/family-life.png'),
  'feeling-secure': require('./feeling-secure/feeling-secure.png'),
  'fun-friendship': require('./fun-friendship/fun-friendship.png'),
  listening: require('./listening/listening.png'),
  'managing-emotions': require('./managing-emotions/managing-emotions.png'),
  'opening-up': require('./opening-up/opening-up.png'),
  'partner-awareness': require('./partner-awareness/partner-awareness.png'),
  'respecting-each-other': require('./respecting-each-other/respecting-each-other.png'),
  'self-awareness': require('./self-awareness/self-awareness.png'),
  'supporting-each-other': require('./supporting-each-other/supporting-each-other.png'),
  info: require('./info/info.png'),
  share_hand: require('./share_hand/share_hand.png'),
  invite_partner: require('./invite_partner/InvitePartner.png'),
  hefalump: require('./hefalump/hefalump.png'),
  Happy: {
    0: require('./Happy0/Icon_happiness-0.png'),
    1: require('./Happy1/Icon_happiness-1.png'),
    2: require('./Happy2/Icon_happiness-2.png'),
    3: require('./Happy3/Icon_happiness-3.png'),
    4: require('./Happy4/Icon_happiness-4.png'),
    5: require('./Happy5/Icon_happiness-5.png'),
    6: require('./Happy6/Icon_happiness-6.png'),
  },
  Icon_Checkbox: require('./Icon_Checkbox/Icon_Checkbox.png'),
  Icon_Checkbox_Filled: require('./Icon_Checkbox_Filled/Icon_Checkbox_Filled.png'),
  Icon_Tick: require('./Icon_Tick/Icon_Tick.png'),
  close: require('./close/close.png'),
  thumb_down: require('./thumb_down/thumb_down.png'),
  thumb_up: require('./thumb_up/thumb_up.png'),
  patterns: [
    require('./pattern_1/pattern_1.png'),
    require('./pattern_2/pattern_2.png'),
    require('./pattern_3/pattern_3.png'),
    require('./pattern_4/pattern_4.png'),
    require('./pattern_5/pattern_5.png'),
  ],
  FeedPlaceholder: require('./image_placeholder/image_placeholder.jpg'),
  course_item_complete: require('./course_item_complete/course_item_complete.png'),
  course_item_complete_partial: require('./course_item_complete_partial/course_item_complete_partial.png'),
  lesson_step_activity: require('./lesson_step_activity/lesson_step_activity.png'),
  lesson_step_article: require('./lesson_step_article/lesson_step_article.png'),
  lesson_step_feedback: require('./lesson_step_feedback/lesson_step_feedback.png'),
  feedback_notes_placeholder: require('./feedback_notes_placeholder/feedback_notes_placeholder.png'),
  feedback_instant: require('./feedback_instant/feedback_instant.png'),
  padlock: require('./padlock/padlock.png'),
  early_bird: require('./early_bird/early_bird.png'),
  activity_logo: require('./activity_logo/activity_logo.png'),
  partner_connect: require('./partner_connect/partner_connect.png'),
  quiz_result_title: require('./quiz_result_title/quiz_result_title.png'),
  painted_border_bottom: require('./painted_border_bottom/painted_border_bottom.png'),
  question_marks: require('./question_marks/question_marks.png'),
  placeholder: require('./image_placeholder/image_placeholder.jpg'),
  conflict: require('./conflict/icConflict.png'),
  goal: require('./goal/goalIcon.png'),
  love_language: require('./love_language/love_language.png'),
  u_got_mail: require('./u_got_mail/u_got_mail.png'),
  free_account: require('./free_account/free_account.png'),
  quiz_icon: require('./quiz_icon/quiz_icon.png'),
  horizon: require('./horizon/horizon.png'),
  sun_cloud: require('./sun_cloud/sun_cloud.png'),
  couple_bike: require('./couple_bike/couple_bike.png'),
  sun_cloud_2: require('./sun_cloud_2/sun_cloud_2.png'),
  character_bike_couple_with_background: require('./character_bike_couple_with_background/character_bike_couple_with_background.png'),
  partner_invite_confirm: require('./partner_invite_confirm/partner_invite_confirm.png'),
  invite_partner_icon: require('./invite_partner_icon/invite_partner_icon.png'),
  google_play_store: require('./google_play_store/google_play_store.png'),
  apple_app_store: require('./apple_app_store/apple_app_store.png'),
  invite_gift: require('./invite_gift/invite_gift.png'),
  bullet_list: require('./bullet_list/bullet_list.png'),
  stopwatch: require('./stopwatch/stopwatch.png'),
  daily_energiser: require('./daily_energiser/daily_energiser.png'),
  lightning: require('./lightning/lightning.png'),
  correct: require('./correct/correct.png'),
  incorrect: require('./incorrect/incorrect.png'),
  daily_conversations_splash: require('./daily_conversations_splash/daily_conversations_splash.png'),
  relationship_skills_splash: require('./relationship_skills_splash/relationship_skills_splash.png'),
  games_and_quizzes_splash: require('./games_and_quizzes_splash/games_and_quizzes_splash.png'),
  how_it_works_splash: require('./how_it_works_splash/how_it_works_splash.png'),
  sparkle: require('./sparkle/sparkle.png'),
  app_of_the_day: require('./app_of_the_day/app_of_the_day.png'),
  text_relish_logo: require('./text_relish_logo/text_relish_logo.png'),
  love_note: require('./love_note/love_note.png'),
  bold_glyph: require('./bold_glyph/bold_glyph.png'),
}

export default {
  ...images,
  traitToSmallImage: {
    'being-grateful': images['being-grateful'],
    'affection-sex': images['affection-sex'],
    'respecting-each-other': images['respecting-each-other'],
    'feeling-secure': images['feeling-secure'],
    'partner-awareness': images['partner-awareness'],
    'family-life': images['family-life'],
    'supporting-each-other': images['supporting-each-other'],
    'self-awareness': images['self-awareness'],
    listening: images.listening,
    communicating: images.communicating,
    complimenting: images.complimenting,
    'fun-friendship': images['fun-friendship'],
    'opening-up': images['opening-up'],
    'managing-emotions': images['managing-emotions'],
    'your-goal': images['goal'],
  },
}
