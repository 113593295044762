import { getType } from 'typesafe-actions'
import { combineEpics, ofType } from 'redux-observable'
import { tap, ignoreElements } from 'rxjs/operators'
import analytics from 'data/analytics'
import SessionActions from './SessionActions'

const AnalyticsSetUserInfoAfterSetSession = (action$, state$) =>
  action$.pipe(
    ofType(getType(SessionActions.setSession)),
    tap(() => {
      analytics.setUserId(state$.value.session.userId)
    }),
    ignoreElements()
  )

export const SessionEpicFactory = () => combineEpics(AnalyticsSetUserInfoAfterSetSession)
