import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { BillingActions } from './actions'

export const BillingReducer = combineReducers({
  purchaseInProgress: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.purchaseCancelled):
        return null
      default:
        return state
    }
  },
})
