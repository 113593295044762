import { combineReducers } from 'redux'
import { fetchEpic } from 'shared/src/data/network'
import { createAsyncAction, getType } from 'typesafe-actions'
import { combineEpics } from 'redux-observable'

export const OnboardActions = {
  onboardComplete: createAsyncAction(
    'onboard/ONBOARD_COMPLETE/REQUEST',
    'onboard/ONBOARD_COMPLETE/SUCCESS',
    'onboard/ONBOARD_COMPLETE/FAILURE'
  )(),
}

const initialState = {}

export const onboardComplete = (state = initialState, action) => {
  switch (action.type) {
    case getType(OnboardActions.onboardComplete.success): {
      return action.payload.content
    }
    default: {
      return state
    }
  }
}

export const OnboardReducer = combineReducers({ onboardComplete })

export const selectOnboardComplete = (state) => state.onboard.onboardComplete

export const OnboardEpicFactory = (networkClient) =>
  combineEpics(
    fetchEpic(
      networkClient,
      OnboardActions.onboardComplete,
      () => `/web-onboarding-complete/:userid`
    )
  )
