import { Platform } from 'react-native'
import StoreService from 'data/StoreService'
import { consoleLog } from 'utils/logging'

const Braze = Platform.OS === 'web' ? require('@braze/web-sdk') : require('react-native-appboy-sdk')

const logCustomEvent = (name, properties = {}) => {
  const state = StoreService.getState()

  properties = {
    ...properties,
    event_name: name,
    platform: Platform.OS,
    subscription_status: state?.billing?.subscriptionStatus,
    user_id: state?.session?.userId,
    partner_up_status: state?.invite?.connectStatus?.status,
    event_source: 'client',
    timestamp: new Date().toISOString(),
  }

  consoleLog('Braze', `${name} - ${JSON.stringify(properties)}`)

  Braze.logCustomEvent(name, properties)
}

// Braze's native code won't cast an integer to string and a native exception will occur if we don't do it here.
const changeUser = (userId) => Braze.changeUser(String(userId))

export default { ...Braze, logCustomEvent, changeUser }
