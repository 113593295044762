// We need this here for @babel/preset-env, it will be transpiled into only the needed polyfills
import 'core-js'

import './global.css'
import 'shared/i18n'
import { version } from './version.json'
import React, { Suspense } from 'react'
import { AppRegistry, Text } from 'react-native'
import { AuthorizationToken } from 'shared/src/AuthToken'
import { configureStore } from './src/data/store'
import { persistStore } from 'redux-persist'
import { RootActions } from 'data/RootActions'
import marketing from 'shared/src/features/marketing'
import analytics from 'shared/src/data/analytics'
import StoreService from 'shared/src/data/StoreService'
import { Provider } from 'react-redux'
import RootComponent from 'RootComponent'
import RootChrome from 'RootChrome'
import * as Sentry from '@sentry/react'
import { getCountry } from 'react-native-localize'
import { v4 as uuidv4 } from 'uuid'
import { WEB_UNIQUE_ID } from 'shared/src/features/experiments/Experiments'
import { PersistGate } from 'redux-persist/lib/integration/react'
import RelishLoadingIndicator from 'shared/src/common/RelishLoadingIndicator'
import { registerCustomIconType } from 'react-native-elements'
import PhosphorIcon from 'shared/src/assets/icons/Phosphor/PhosphorIcon'
import * as performanceReporting from './performanceReporting'
import Braze from 'shared/src/data/braze'

const urlParams = new URLSearchParams(window.location.search)
const urlParamValue = urlParams.get(WEB_UNIQUE_ID)

if (urlParamValue) {
  window.localStorage.setItem(WEB_UNIQUE_ID, urlParamValue)
} else if (!window.localStorage.getItem(WEB_UNIQUE_ID)) {
  window.localStorage.setItem(WEB_UNIQUE_ID, uuidv4())
}

if (!__DEV__) {
  Sentry.init({
    dsn: 'https://e3114c608b6142cc9cf2f2e1ed9f7b24@o198261.ingest.sentry.io/5689624',
  })
}

marketing.init()

Braze.initialize(
  __DEV__ ? '424aff7a-375c-4c59-ab52-e7346f47ecac' : '4646f1ec-dcd3-4e4b-a384-4f106816f098',
  {
    baseUrl: 'sdk.iad-05.braze.com',
  }
)
Braze.openSession()

Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.allowFontScaling = false

const store = configureStore({
  Authorization: AuthorizationToken,
  appVersion: version,
  country: getCountry(),
  attributionId: marketing.getAttributionId(),
  utm: marketing.getUTM(),
  fbc: marketing.getFBC(),
  gclid: marketing.getGCLID(),
})

const persistor = persistStore(store, null, () => {
  store.dispatch(RootActions.session.appLaunch())
})

if (module.hot) {
  module.hot.accept()
}

analytics.init(
  {
    includeReferrer: true,
    saveParamsReferrerOncePerSession: false,
    deviceId: window.localStorage.getItem(WEB_UNIQUE_ID),
  },
  version
)

StoreService.setGlobalStoreRef(store)
registerCustomIconType('phosphor', PhosphorIcon)

AppRegistry.registerComponent('relish', () => () => (
  <Provider store={store}>
    <Suspense fallback={<RelishLoadingIndicator />}>
      <RootChrome>
        <PersistGate loading={<RelishLoadingIndicator />} persistor={persistor}>
          <RootComponent store={store} />
        </PersistGate>
      </RootChrome>
    </Suspense>
  </Provider>
))

AppRegistry.runApplication('relish', {
  initialProps: {},
  rootTag: document.getElementById('react-root'),
})

performanceReporting.init()
