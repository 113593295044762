import { noop } from 'lodash/fp'

// *** We ideally want to keep the use of this service to a MINIMUM. ***

// There should be a _very_ good reason to use this direct access to the
// store over a standard react-redux connected component.

let _store = null
const setGlobalStoreRef = (store) => (_store = store)

const dispatch = (x) => (_store ? _store.dispatch(x) : noop)

const getState = () => (_store ? _store.getState() : noop)

export default {
  setGlobalStoreRef,
  dispatch,
  getState,
}
