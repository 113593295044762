import { Platform } from 'react-native'
import { persistReducer as _persistReducer } from 'redux-persist'

// This is a simplified version of the reduce-reducers package
// which allows us to reduce multiple reducers into a single reducer.
// It can be used in place of combineReducers where we want a single
// reducer instead of reducers at specific keys.
//
// Note: we spread newState onto the result of the reducer to get around this issue here: https://github.com/reduxjs/redux/pull/2059
// where combineReducers removes extra keys passed to it. Ideally we should write our own version of combineReducers to make things more composable.
// Currently you will receieve a warning message from combineReducers about extra keys.
export const reduceReducers = (...reducers) => (prevState, value) =>
  reducers.reduce((newState, reducer) => ({ ...newState, ...reducer(newState, value) }), prevState)

// This wraps redux-persist's persistReducer so that on web we use sessionStorage (rather than localStorage)
// and on mobile we use AsyncStorage
export const persistReducer = (config = {}, reducer) =>
  _persistReducer(
    {
      storage:
        Platform.OS === 'web'
          ? require('redux-persist/lib/storage/session').default
          : require('@react-native-async-storage/async-storage').default,
      ...config,
    },
    reducer
  )
