import { createAsyncAction, createAction } from 'typesafe-actions'

export const InviteActions = {
  fetchCreateInvite: createAsyncAction(
    'invite/FETCH_CREATE_INVITE/REQUEST',
    'invite/FETCH_CREATE_INVITE/RECEIVE_SUCCESS',
    'invite/FETCH_CREATE_INVITE/RECEIVE_FAILURE'
  )(),
  fetchPartnerConnectMessage: createAsyncAction(
    'invite/FETCH_PARTNER_CONNECT_MESSAGE/REQUEST',
    'invite/FETCH_PARTNER_CONNECT_MESSAGE/RECEIVE_SUCCESS',
    'invite/FETCH_PARTNER_CONNECT_MESSAGE/RECEIVE_FAILURE'
  )(),
  dismissPartnerConnectMessage: createAction('invite/DISMISS_INVITE_MESSAGE')(),
  requestDisconnect: createAsyncAction(
    'invite/DISCONNECT/REQUEST',
    'invite/DISCONNECT/DISCONNECT_SUCCESS',
    'invite/DISCONNECT/DISCONNECT_FAILURE'
  )(),
  requestDisconnectReset: createAction('invite/DISCONNECT/REQUEST_RESET')(),
  requestTokenConnect: createAsyncAction(
    'invite/REQUEST_TOKEN_CONNECT/REQUEST',
    'invite/REQUEST_TOKEN_CONNECT/RECEIVE_SUCCESS',
    'invite/REQUEST_TOKEN_CONNECT/RECEIVE_FAILURE'
  )(),
  resetTokenConnect: createAction('invite/RESET_TOKEN_CONNECT')(),
  storePartnerConnectionToken: createAction('invite/STORE_PARTNER_CONNECTION_TOKEN')(),
  resetPartnerConnectionToken: createAction('invite/RESET_PARTNER_CONNECTION_TOKEN')(),
  createFriendReferral: createAsyncAction(
    'invite/FETCH_CREATE_FRIEND_REFERRAL/REQUEST',
    'invite/FETCH_CREATE_FRIEND_REFERRAL/RECEIVE_SUCCESS',
    'invite/FETCH_CREATE_FRIEND_REFERRAL/RECEIVE_FAILURE'
  )(),
}
