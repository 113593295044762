import profileEn from './profile.json'
import settingsEn from './settings.json'
import homeEn from './home.json'
import loginEn from './login.json'
import welcomeEn from './welcome.json'
import signupEn from './signup.json'
import billingEn from './billing.json'
import journalEn from './journal.json'
import planEn from './plan.json'
import feedEn from './feed.json'
import networkEn from './network.json'
import errorsEn from './errors.json'
import coursesEn from './courses.json'
import partnerEn from './partnerconnect.json'
import discoverEn from './discover.json'
import quizEn from './quiz.json'
import onboardEn from './onboard.json'
import relationshipHealthEn from './relationshipHealth.json'
import progressEn from './progress.json'
import emailLogin from './emailLogin.json'

export default {
  network: networkEn,
  profile: profileEn,
  settings: settingsEn,
  home: homeEn,
  login: loginEn,
  welcome: welcomeEn,
  signup: signupEn,
  billing: billingEn,
  journal: journalEn,
  plan: planEn,
  feed: feedEn,
  errors: errorsEn,
  courses: coursesEn,
  partner: partnerEn,
  discover: discoverEn,
  quiz: quizEn,
  onboard: onboardEn,
  relationshipHealth: relationshipHealthEn,
  progress: progressEn,
  emailLogin: emailLogin,
}
