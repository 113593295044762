import { getType } from 'typesafe-actions'
import { combineReducers } from 'redux'
import { set, isNil } from 'lodash/fp'
import { InviteActions } from './actions'
import { errorDocument } from 'utils/ApiFactory'
import { requestInProgressReducer } from 'data/network'
import { persistReducer } from 'utils/ReduxUtils'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

export const InvitationReducer = (state = null, action) => {
  switch (action.type) {
    case getType(InviteActions.fetchCreateInvite.success):
      return action.payload.response.content
    default:
      return state
  }
}

const RequestsInProgressReducer = combineReducers({
  disconnect: requestInProgressReducer(InviteActions.requestDisconnect),
})

export const PartnerConnectStatusReducer = (state = null, action) => {
  switch (action.type) {
    case getType(InviteActions.fetchPartnerConnectMessage.success): {
      return {
        status: action.payload.status,
        connectionDetails: action.payload.connectionDetails,
        shareWithPartner: action.payload.shareWithPartner,
        ...action.payload.message,
        dismissed:
          !isNil(state) && state.text === action.payload.message.text ? state.dismissed : false,
      }
    }
    case getType(InviteActions.dismissPartnerConnectMessage):
      return set('dismissed', true)(state)
    default:
      return state
  }
}

export const DisconnectReducer = (state = { status: null, data: null, error: null }, action) => {
  switch (action.type) {
    case getType(InviteActions.requestDisconnect.success): {
      return {
        status: action.payload.status,
        data: action.payload.response,
        error: null,
      }
    }
    case getType(InviteActions.requestDisconnect.failure): {
      return {
        status: action.payload.status,
        data: null,
        error: errorDocument(action.payload, 'Something went wrong'),
      }
    }
    case getType(InviteActions.requestDisconnectReset):
      return { status: null, data: null, error: null }
    default:
      return state
  }
}

export const MagicInviteReducer = (state = {}, action) => {
  switch (action.type) {
    case getType(InviteActions.fetchCreateInvite.success):
      return action.payload.response.magicInviteContent
    default:
      return state
  }
}

export const TokenConnectionRequestReducer = (
  state = { inProgress: false, status: null, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getType(InviteActions.requestTokenConnect.request):
      return { inProgress: true, status: null, data: null, error: null }
    case getType(InviteActions.requestTokenConnect.success):
      return {
        inProgress: false,
        status: action.payload.status,
        data: action.payload.response,
        error: null,
      }
    case getType(InviteActions.requestTokenConnect.failure):
      return {
        inProgress: false,
        status: action.payload.status,
        data: null,
        error: action.payload.response,
      }
    case getType(InviteActions.resetTokenConnect):
      return { inProgress: false, status: null, data: null, error: null }
    default: {
      return state
    }
  }
}

export const PartnerConnectionTokenReducer = (state = null, action) => {
  switch (action.type) {
    case getType(InviteActions.storePartnerConnectionToken):
      return action.payload
    case getType(InviteActions.resetPartnerConnectionToken):
      return null
    default: {
      return state
    }
  }
}

export const FriendReferralReducer = (state = null, action) => {
  switch (action.type) {
    case getType(InviteActions.createFriendReferral.success):
      return action.payload.content
    default:
      return state
  }
}

const persistConfig = {
  key: 'invite',
  whitelist: ['connectStatus', 'partnerConnectionToken'],
  stateReconciler: autoMergeLevel2,
}

export const InviteReducer = persistReducer(persistConfig, (state, action) =>
  combineReducers({
    requestsInProgress: RequestsInProgressReducer,
    invitation: InvitationReducer,
    connectStatus: PartnerConnectStatusReducer,
    disconnectResponse: DisconnectReducer,
    magicInvite: MagicInviteReducer,
    tokenConnection: TokenConnectionRequestReducer,
    partnerConnectionToken: PartnerConnectionTokenReducer,
    friendReferral: FriendReferralReducer,
  })(state, action)
)
