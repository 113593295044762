import { Platform } from 'react-native'
import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { BillingActions } from './actions'
import { requestInProgressReducer } from 'data/network'
import { persistReducer } from 'utils/ReduxUtils'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const billingReducer = combineReducers({
  purchaseInProgress: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.beginPurchase):
        return action.payload
      case getType(BillingActions.purchaseComplete):
        return null
      default:
        return state
    }
  },
  closePaywall: (state = false, action) => {
    switch (action.type) {
      case getType(BillingActions.purchaseComplete):
        return { shouldClose: true, deeplink: action.payload?.postPurchaseDeeplink }
      case getType(BillingActions.upsellAction):
        return { shouldClose: false }
      default:
        return state
    }
  },
  verifyingPurchases: requestInProgressReducer(BillingActions.verifyPurchasesRequest),
  hasShownOnboardingPaywall: (state = false, action) => {
    switch (action.type) {
      case getType(BillingActions.upsellAction):
        return true
      default:
        return state
    }
  },
  delayedUpsellAction: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.upsellAction):
        return null
      case getType(BillingActions.delayUpsellAction):
        return action
      default:
        return state
    }
  },
  upsell: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.verifyPurchasesRequest.success):
        return action.payload.response.upsell_data
      default:
        return state
    }
  },
  paywall: (state = {}, action) => {
    switch (action.type) {
      case getType(BillingActions.fetchPaywall.request):
        return { data: null }
      case getType(BillingActions.presentPaywall):
        return { data: action.payload.paywall }
      default:
        return state
    }
  },
  features: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.verifyPurchasesRequest.success):
        return action.payload.response.features
      default:
        return state
    }
  },
  subscriptionStatus: (state = null, action) => {
    switch (action.type) {
      case getType(BillingActions.verifyPurchasesRequest.success):
        return action.payload.response.subscription_status
      default:
        return state
    }
  },
})

// We persist `hasShownOnboardingPaywall` & `purchaseInProgess` on web as we have to
// navigate to stripe and need these 2 keys when we get redirected back
// We persist `subscriptionStatus` so that analytics events always have that property
// after it has been fetched once
const persistConfig = {
  key: 'billing',
  whitelist: ['hasShownOnboardingPaywall', 'purchaseInProgress', 'subscriptionStatus'],
  stateReconciler: autoMergeLevel2,
}

export const BillingReducer =
  Platform.OS === 'web' ? persistReducer(persistConfig, billingReducer) : billingReducer
