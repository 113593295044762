import images from './images'
import colors from './colors'
import styles from './styles'
import fonts from './fonts'
import constants from './constants'
import fontStyles from './fontStyles'
import animations from './animations'

export default {
  images,
  colors,
  styles,
  fonts,
  constants,
  fontStyles,
  animations,
}
