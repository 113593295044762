// import amplitude from 'amplitude-js'
import { screenEventMap, screenEventParamsMap, screenEventNamesMap } from 'data/screenEventMap'
import { consoleLog } from 'utils/logging'
import keyvalue from 'data/keyvalue'
import {
  isNil,
  // omit,
  pick,
} from 'lodash/fp'
// import { merge } from 'lodash/fp'
import { Platform } from 'react-native'
import StoreService from './StoreService'

const init = () =>
  // extraOptions = {}, appVersion
  {
    // const key = __DEV__ ? '5214782022333e8606fa189619817b7f' : '7fbdbf469f9187405e9234cd62e99424'

    // const defaultOptions = {
    //   trackingOptions: {
    //     city: false,
    //     country: true,
    //     carrier: false,
    //     device_manufacturer: false,
    //     device_model: true,
    //     dma: false,
    //     ip_address: false,
    //     language: true,
    //     os_name: true,
    //     os_version: true,
    //     platform: true,
    //     region: false,
    //     version_name: true,
    //   },
    // }

    // amplitude.getInstance().init(key, null, merge(defaultOptions, extraOptions))
    // if (Platform.OS === 'web') amplitude.getInstance().setVersionName(appVersion)
    logEvent('Launch')
    keyvalue.get('Launch', 'HasLaunchedBefore', 'false', (launchedBefore) => {
      if (launchedBefore !== 'true') {
        logEvent('First Launch')
        keyvalue.set('Launch', 'HasLaunchedBefore', 'true')
      }
    })
  }

const setUserId = (userid) => {
  if (!isNil(userid)) {
    consoleLog('Analytics', `Set User Id - ${userid}`)
    // amplitude.getInstance().setUserId(userid)
  } else {
    consoleLog('Analytics', 'Not setting nil userid')
  }
}

const setDeviceId = (deviceId) => {
  if (!isNil(deviceId)) {
    consoleLog('Analytics', `Set Device Id - ${deviceId}`)
    // amplitude.getInstance().setDeviceId(deviceId)
  } else {
    consoleLog('Analytics', 'Not setting nil deviceId')
  }
}

const setUserProperties = (properties) => {
  consoleLog('Analytics', `Set User Properties - ${JSON.stringify(properties)}`)
  // amplitude.getInstance().setUserProperties(properties)
}

const logEvent = (name, properties = {}) => {
  const state = StoreService.getState()

  properties = {
    ...properties,
    event_name: name,
    platform: Platform.OS,
    subscription_status: state?.billing?.subscriptionStatus,
    user_id: state?.session?.userId,
    partner_up_status: state?.invite?.connectStatus?.status,
    event_source: 'client',
    timestamp: new Date().toISOString(),
  }

  consoleLog('Analytics', `${name} - ${JSON.stringify(properties)}`)

  // amplitude.getInstance().logEvent(name, omit(['token', 't', 'email'], properties))
}

const logScreenView = ({ name, prevName, params }) => {
  const filteredParams = pick(['source'], params)
  const screenName = screenEventNamesMap[name]?.(filteredParams) || screenEventMap[name] || name
  const prevScreenName = screenEventMap[prevName] || prevName
  const mappedParams =
    name in screenEventParamsMap ? screenEventParamsMap[name](filteredParams) : {}

  logEvent(`${screenName} Screen Viewed`, {
    Source: prevScreenName,
    ...filteredParams,
    ...mappedParams,
  })
}

const logNetworkEvent = (tracking, payload) => {
  logEvent(tracking.event, tracking.params === undefined ? {} : tracking.params(payload))
}

export default {
  init,
  setUserId,
  setUserProperties,
  logEvent,
  logScreenView,
  logNetworkEvent,
  setDeviceId,
}
