export const consoleLog = (tag, message) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(`[${ucFirst(tag)}] ${message}`)
  }
}

export const consoleWarn = (tag, message) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.warn(`[${ucFirst(tag)}] ${message}`)
  }
}

function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
