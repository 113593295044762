import React, { useRef, lazy } from 'react'
import { useSelector } from 'react-redux'
import { createRenderer } from 'fela-native'
import { RendererProvider } from 'react-fela'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import Connectivity from 'shared/src/network/Connectivity'
import { isLoggedIn } from 'shared/src/features/login/store/selectors'
import { navigationRef } from 'shared/src/features/navigation/NavigationService'
import { DeeplinkMap } from 'DeeplinkMap'
import analytics from 'shared/src/data/analytics'

const LoggedInNavigator = lazy(() => import(/* webpackChunkName: "LoggedIn" */ 'LoggedInNavigator'))
const LoggedOutNavigator = lazy(() =>
  import(/* webpackChunkName: "LoggedOut" */ 'LoggedOutNavigator')
)

const stylesRenderer = createRenderer()
const Stack = createStackNavigator()

const RootComponent = ({ store }) => {
  const routeNameRef = useRef()
  const loggedIn = useSelector(isLoggedIn)

  return (
    <NavigationContainer
      documentTitle={{ enabled: false }}
      linking={DeeplinkMap(store)}
      ref={navigationRef}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current
        const currentRoute = navigationRef.current.getCurrentRoute()

        if (previousRouteName !== currentRoute.name) {
          analytics.logScreenView({
            name: currentRoute.name,
            prevName: previousRouteName,
            params: currentRoute.params,
          })
        }

        routeNameRef.current = currentRoute.name
      }}
    >
      <RendererProvider renderer={stylesRenderer}>
        <Stack.Navigator headerMode="none">
          {loggedIn ? (
            <Stack.Screen name="LoggedIn" component={LoggedInNavigator} />
          ) : (
            <Stack.Screen name="LoggedOut" component={LoggedOutNavigator} />
          )}
        </Stack.Navigator>
        <Connectivity />
      </RendererProvider>
    </NavigationContainer>
  )
}

export default RootComponent
