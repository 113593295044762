import AsyncStorage from '@react-native-async-storage/async-storage'

const set = (
  collection = 'Default',
  key,
  value,
  callback = (error) => (error ? console.warn(error.stack) : {}) // eslint-disable-line no-console
) => {
  AsyncStorage.setItem(`@${collection}:${key}`, value, callback)
}

const get = (collection = 'Default', key, def, callback) => {
  AsyncStorage.getItem(`@${collection}:${key}`, (error, result) =>
    error ? callback(def) : callback(result)
  )
}

export default {
  set,
  get,
}
