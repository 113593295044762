import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { errorDocument } from 'utils/ApiFactory'
import SessionActions from 'features/session/store/SessionActions'
import { REHYDRATE } from 'redux-persist'
import { persistReducer } from 'utils/ReduxUtils'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { OnboardActions } from './OnboardActions'
import { personaliseQuestion } from './OnboardQuestionHelper'
import { OnboardQuestionSource } from 'features/onboard/screens/OnboardQuestionSource'
import { isNil } from 'lodash/fp'

const initialQuestionState = {
  sections: [],
  sectionIndex: 0,
  questionIndex: 0,
  question: {},
  currentComponentKey: '0-0',
  navigateDirection: 'forward',
  collected: {},
  source: OnboardQuestionSource.signup,
  history: [{ sectionIndex: 0, questionIndex: 0 }],
}

const initialRetakeProfileSectionState = {
  ...initialQuestionState,
  source: undefined,
}

const initialRetakeOnboardQuestionState = {
  ...initialQuestionState,
  source: OnboardQuestionSource.retake,
}

const onboardFlow = (state = initialQuestionState, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.key === 'onboardQuestions') {
        return {
          ...state,
          sections: action.payload.sections,
          sectionIndex: action.payload.sectionIndex,
          questionIndex: action.payload.questionIndex,
          question: action.payload.question,
          currentComponentKey: `${action.payload.sectionIndex}-${action.payload.questionIndex}`,
          navigateDirection: action.payload.navigateDirection,
          collected: action.payload.collected,
          history: action.payload.history,
        }
      } else {
        return state
      }
    case getType(OnboardActions.commitNewSections): {
      return {
        ...(state.source == OnboardQuestionSource.retake
          ? initialRetakeOnboardQuestionState
          : initialQuestionState),
        sections: action.payload.sections,
        question: action.payload.sections[state.sectionIndex].pages[state.questionIndex],
      }
    }
    case getType(SessionActions.logout):
    case getType(OnboardActions.resetQuestions):
      return initialQuestionState
    case getType(OnboardActions.nextQuestion): {
      const section = state.sections[state.sectionIndex]
      let newQuestionIndex = state.questionIndex
      let newSectionIndex = state.sectionIndex
      if (state.questionIndex < section.pages.length - 1) {
        newQuestionIndex = state.questionIndex + 1
      } else if (state.sectionIndex < state.sections.length - 1) {
        newSectionIndex = state.sectionIndex + 1
        newQuestionIndex = 0
      } else {
        return state
      }
      return {
        ...state,
        question: personaliseQuestion(state.sections[newSectionIndex].pages[newQuestionIndex], {
          ...state.collected,
        }),
        sectionIndex: newSectionIndex,
        questionIndex: newQuestionIndex,
        currentComponentKey: `${newSectionIndex}-${newQuestionIndex}`,
        navigateDirection: 'forward',
        history: [
          ...state.history,
          { sectionIndex: newSectionIndex, questionIndex: newQuestionIndex },
        ],
      }
    }
    case getType(OnboardActions.prevQuestion): {
      if (state.history.length === 1) {
        return state
      }
      let updatedHistory = state.history.slice(0, -1)
      let prevIndex = updatedHistory[updatedHistory.length - 1]
      let prevQuestion = personaliseQuestion(
        state.sections[prevIndex.sectionIndex].pages[prevIndex.questionIndex],
        state.collected
      )
      return {
        ...state,
        question: prevQuestion,
        sectionIndex: prevIndex.sectionIndex,
        questionIndex: prevIndex.questionIndex,
        currentComponentKey: `${prevIndex.sectionIndex}-${prevIndex.questionIndex}`,
        navigateDirection: 'backward',
        history: updatedHistory,
      }
    }
    case getType(OnboardActions.collect): {
      if (isNil(action.payload.collectKey)) {
        return state
      } else {
        return {
          ...state,
          collected: {
            ...state.collected,
            [action.payload.collectKey]: action.payload.collectValue,
          },
        }
      }
    }
    case getType(OnboardActions.collectMultiple):
      return {
        ...state,
        collected: { ...state.collected, ...action.payload },
      }
    case getType(OnboardActions.retakeProfileReset): {
      return initialRetakeProfileSectionState
    }
    case getType(OnboardActions.retakeProfile): {
      return {
        ...initialRetakeProfileSectionState,
        sections: [action.payload],
        question: action.payload.pages[0],
      }
    }
    case getType(OnboardActions.retakeOnboard): {
      return initialRetakeOnboardQuestionState
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'onboardQuestions',
  timeout: null,
  stateReconciler: autoMergeLevel2,
}

const fetchSectionsReducer = (state = { delay: 100, waiting: false }, action) => {
  switch (action.type) {
    case getType(OnboardActions.fetchSections.success): {
      return { ...state, delay: 100 }
    }
    case getType(OnboardActions.fetchSections.failure): {
      return { ...state, delay: state.delay * 10 }
    }
    default:
      return state
  }
}

export const GenerateNewInsightsReducer = (state = false, action) => {
  switch (action.type) {
    case getType(OnboardActions.generatingNewInsights):
      return true
    case getType(OnboardActions.showNewInsights):
      return false
    default:
      return state
  }
}

const initialState = { fetching: false, status: null, data: null, error: null }

const generateProfileActionReducer = (profileAction) => (state = initialState, action) => {
  switch (action.type) {
    case getType(profileAction.request):
      return {
        ...state,
        fetching: true,
        status: null,
        data: null,
        error: null,
      }
    case getType(profileAction.success):
      return {
        fetching: false,
        status: action.payload.status,
        data: action.payload.response,
        error: null,
      }
    case getType(profileAction.failure):
      return {
        fetching: false,
        status: action.payload.status,
        data: null,
        error: errorDocument(action.payload),
      }
    case getType(profileAction.reset):
      return initialState
    default:
      return state
  }
}

export const onboardFlowReducer = persistReducer(persistConfig, onboardFlow)

export const OnboardReducer = combineReducers({
  questions: onboardFlowReducer,
  fetchSections: fetchSectionsReducer,
  postProfile: generateProfileActionReducer(OnboardActions.requestPostProfile),
  updateIdentity: generateProfileActionReducer(OnboardActions.requestUpdateIdentity),
  generateNewInsights: GenerateNewInsightsReducer,
})
