import SessionActions from 'shared/src/features/session/store/SessionActions'
import { ConfigDebugActions } from 'shared/src/features/debug/store/actions'
import { LoginActions } from 'shared/src/features/login/store/actions'
import { NetworkActions } from 'shared/src/network/store'
import { InviteActions } from 'shared/src/features/invite/store/actions'
import { OnboardActions } from 'shared/src/features/onboard/store/OnboardActions'
import { BillingActions } from 'features/billing/store/actions'
import { BillingActions as SharedBillingActions } from 'shared/src/features/billing/store/actions'
import { PreloginActions } from 'shared/src/features/prelogin/store'

export const RootActions = {
  network: NetworkActions,
  session: SessionActions,
  config: ConfigDebugActions,
  login: LoginActions,
  invite: InviteActions,
  onboard: OnboardActions,
  billing: { ...BillingActions, ...SharedBillingActions },
  prelogin: PreloginActions,
}
