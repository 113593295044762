// We should move some of these to shared so we can reuse between web and mobile, but leaving here for now as not sure if we'll need custom config for web.
import { parseUrl } from 'query-string'
import { InviteActions } from 'shared/src/features/invite/store/actions'
import { getStateFromPath } from '@react-navigation/native'
import { isLoggedIn } from 'shared/src/features/login/store/selectors'

const customGetStateFromPath = (path, options, store) => {
  const { url, query } = parseUrl(path)

  if (url.endsWith('invite/token') && query.t && !isLoggedIn(store.getState())) {
    store.dispatch(InviteActions.storePartnerConnectionToken(query.t))
  }

  return getStateFromPath(path, options)
}

export const DeeplinkMap = (store) => {
  return {
    prefixes: [
      'https://hellorelish.com',
      'https://www.hellorelish.com',
      'www.hellorelish.com',
      'hellorelish.com',
      'hellorelish:',
      'get.hellorelish.com',
      'localhost:3000',
      'https://hellorelish.onelink.me/YQ5e',
      'https://hellorelish.herokuapp.com',
    ],
    config: {
      screens: {
        LoggedIn: {
          initialRouteName: 'Onboard',
          path: '',
          screens: {
            Onboard: 'onboard',
            OnboardComplete: 'onboardComplete',
            Paywall: 'paywall',
            CheckoutRedirect: 'checkout/:status',
            InvitePartner: 'invitePartner',
          },
        },
        LoggedOut: {
          path: 'login',
          screens: {
            Prelogin: 'welcome',
            EmailEntry: 'email',
            TokenAuth: 'tokenAuth',
          },
        },
      },
    },
    getStateFromPath: (path, options) => customGetStateFromPath(path, options, store),
  }
}
