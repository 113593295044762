import { combineReducers } from 'redux'
import { fetchEpic, requestInProgressReducer } from 'data/network'
import { errorDocument } from 'utils/ApiFactory'
import { createAsyncAction, getType } from 'typesafe-actions'
import { combineEpics, ofType } from 'redux-observable'
import { isEmpty } from 'lodash/fp'
import { filter, ignoreElements, tap } from 'rxjs/operators'
import NavigationService from 'features/navigation/NavigationService'

export const PreloginActions = {
  fetchPages: createAsyncAction(
    'prelogin/FETCH_PAGES/REQUEST',
    'prelogin/FETCH_PAGES/SUCCESS',
    'prelogin/FETCH_PAGES/FAILURE'
  )(),
}

const pages = (state = null, action) => {
  switch (action.type) {
    case getType(PreloginActions.fetchPages.success):
      return action.payload.pages
    default:
      return state
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case getType(PreloginActions.fetchPages.failure):
      return errorDocument(action.payload)
    default:
      return state
  }
}

const loginScreen = (state = null, action) => {
  switch (action.type) {
    case getType(PreloginActions.fetchPages.success):
      return action.payload.loginScreen
    default:
      return state
  }
}

export const PreloginReducer = combineReducers({
  loginScreen,
  pages,
  error,
  inProgress: requestInProgressReducer(PreloginActions.fetchPages),
})

export const selectPrelogin = (state) => state.prelogin

export const NavigateToEmailScreenIfNoPages = (action$) =>
  action$.pipe(
    ofType(getType(PreloginActions.fetchPages.success)),
    filter(({ payload }) => isEmpty(payload.pages)),
    tap(() => NavigationService.replace('EmailEntry')),
    ignoreElements()
  )

export const PreloginEpicFactory = (networkClient) =>
  combineEpics(
    fetchEpic(networkClient, PreloginActions.fetchPages, ({ token }) =>
      token ? `/pre-login-pages?token=${token}` : '/pre-login-pages'
    ),
    NavigateToEmailScreenIfNoPages
  )
