import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import NetworkClient from 'network/client'
import analytics from 'data/analytics'

const createNetworkAnalyticsMiddleware = (networkEvents) => () => (next) => (action) => {
  if (networkEvents[action.type]) {
    analytics.logNetworkEvent(networkEvents[action.type], action.payload)
  }

  return next(action)
}

// Set up a store with root reducer, epic and network analytics middleware
export const makeStore = ({
  networkConfig,
  networkEvents,
  rootReducer,
  rootEpic,
  extraMiddleware = [],
}) => {
  const epicMiddleware = createEpicMiddleware()
  const networkAnalyticsMiddleware = createNetworkAnalyticsMiddleware(networkEvents)
  const middleware = [epicMiddleware, networkAnalyticsMiddleware]

  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...[...middleware, ...extraMiddleware]))
  )
  epicMiddleware.run(rootEpic(NetworkClient(networkConfig)))

  return store
}
