const fonts = {
  dmsans: {
    regular: 'DMSans-Regular',
    medium: 'DMSans-Medium',
    mediumitalic: 'DMSans-MediumItalic',
    bold: 'DMSans-Bold',
  },
  dmserif: {
    regular: 'DMSerifText-Regular',
  },
  modernEra: {
    regular: 'ModernEra-Regular',
    medium: 'ModernEra-Medium',
    bold: 'ModernEra-Bold',
    extraBold: 'ModernEra-ExtraBold',
  },
  moranga: {
    regular: 'Moranga-Regular',
    medium: 'Moranga-Medium',
    light: 'Moranga-Light',
  },
}

export default fonts
