import { combineEpics, ofType } from 'redux-observable'
import { getType } from 'typesafe-actions'
import { map, filter } from 'rxjs/operators'
import { InviteActions } from 'shared/src/features/invite/store/actions'
import SessionActions from 'shared/src/features/session/store/SessionActions'

export const SkipOnboardingWhenTokenConnectionRequestSuccess = (action$) =>
  action$.pipe(
    ofType(getType(InviteActions.requestTokenConnect.success)),
    filter(({ payload }) => payload.response.skipOnboarding),
    map(() => SessionActions.setProfileComplete({ profileComplete: true }))
  )

export const InvitePartnerEpicFactory = () =>
  combineEpics(SkipOnboardingWhenTokenConnectionRequestSuccess)
