const colors = {
  transparent: '#00000000',
  white: '#ffffff',
  white_25_percent: '#ffffff20',
  white_50_percent: '#ffffffbb',
  black: '#000000',
  black_25_percent: '#00000040',
  black_50_percent: '#000000bb',
  black_75_percent: '#00000088',
  splash_screen_peach: '#fbefe9',
  midnight: '#2d2a44',
  transparentMidnight: '#cbcad0',
  oatmeal: '#fbf8f5',

  greyishBrown: '#4a4a4a',
  warmGreyLight: '#faf9f9',
  warmGrey: '#9b9b9b',
  warmGreyDark: '#7d7d7d',
  creamDream: '#fff9e6',
  greenyBlue: '#5cb4a6',
  paleBlue: '#F0F8FF',
  beige: '#f6d6cd',
  palePeach: '#ffebb4',
  palePeach2: '#fdebbb',
  paleTurquoise: '#b3e1dc',
  eggShell: '#e5e2d8',
  seaFoamBlue: '#77d0b1',
  coolMint: '#f5fffa',
  candlelight: '#fffdf6',
  cornflowerBlue: '#8181fe',
  oldPaper: '#FFF9F5',
  matteCoral: '#FFA187',
  zing: '#92E2B5',
  emerald: '#30956a',
  paper: '#FFFCF7',
  mist: '#F8F8FF',

  dark: '#4a4a4a',
  charcoal: '#4a4a4a',
  dark_8_percent: '#1e243614',
  dark_50_percent: '#1e24367e',
  modalWindowBackground: 'rgba(0, 0, 0, 0.3)',
  shadowColor: 'rgba(0, 0, 0, 0.4)',
  shadowColorLight: 'rgba(0, 0, 0, 0.4)',
  shadowColorLighter: 'rgba(0, 0, 0, 0.1)',

  lightestGrey: '#F9F9F9',
  veryLightGrey: '#eeeeee',
  grey_light: '#ebebeb',
  grey_medium: '#cecece',
  grey_medium_dark: '#afafaf',
  grey_dark: '#656a77',
  lightGrey: '#f0f0f0',

  yellow: '#febd11',
  yellow_50_percent: 'rgba(254,189,17, 0.5)',
  yellow_dark: '#fd9c0c',
  yellow_light: '#fffdf6',
  yellow_light2: '#f9f5e4',
  yellow_light_mid: '#ffde82',
  yellow_light_mid_2: '#ffde84',
  yellowThere: '#FFB24F',
  mellowYellow: '#FFDE82',
  card: '#F5ECD6',

  red: '#ec6745',
  red_light: '#ff7648',
  red_peach: '#e17753',
  wrong: '#FF6C4B',

  green_dark: '#28845d',
  green_light: '#92e2b5',

  lilac_pale: '#e3e3ff',
  blue: '#ecfafb',
  coolBlue: '#dfe9fc',
  brightBlue: '#8181FE',
  articleColor: '#358d96',

  purple_light: '#f5f0ff',
  purple: '#dfd0ff',
  purple_mid_dark: '#e8dcff',
  purple_dark: '#c2b3dd',
  purple2: '#b1b0ef',

  off_white: '#fffcf3',
  off_white_2: '#fffdf6',
  light_teal: '#92e2b5',
  light_teal_2: '#a0e4db',

  teal: '#92E2B5',
  tellTeal: '#7AD0B2',
  petal: '#FDEFE4',

  slate: '#5C6677',
  lightSlate: '#7a859a',

  input_accessory_bg: '#f8f8f8de',
  separator: '#ced0ce',
  tableSectionHeader: '#f7f7f7',

  sunset: '#FFAC78',
  blueberry: '#8181FE',

  csi: [
    '#E17753',
    '#E57E4B',
    '#E98344',
    '#EE8B3A',
    '#F3932F',
    '#F79C26',
    '#F9A71F',
    '#FCB218',
    '#FEBC12',
    '#FDC83C',
    '#FCD262',
    '#FBDC88',
    '#F3DE92',
    '#E9DE96',
    '#E0DD9A',
    '#D2DD9E',
    '#BDDBA3',
    '#A5DAA7',
    '#90D9AC',
    '#85D7AE',
    '#80D4AF',
    '#79D0B1',
  ],

  biggerRevealCsi: [
    '#fe6b4a',
    '#fe6b4a',
    '#fe6b4a',
    '#fe6b4a',
    '#fe6b4a',
    '#fe6b4a',
    '#fe6b4a',
    '#ff6c4a',
    '#ff6c4a',
    '#ff6c4a',
    '#fe854c',
    '#ff944d',
    '#fea34d',
    '#ffb14e',
    '#ffb24e',
    '#ffb14d',
    '#ecb65e',
    '#c8bd78',
    '#a8c48d',
    '#87cda7',
    '#79d0b2',
    '#79d0b2',
  ],

  traits: {
    'being-grateful': '#ef923f',
    'affection-sex': '#efbdb0',
    'respecting-each-other': '#e17753',
    'feeling-secure': '#b3e2dc',
    'partner-awareness': '#ffde82',
    'family-life': '#f6d6cd',
    'supporting-each-other': '#b1b0ef',
    'self-awareness': '#ffde82',
    listening: '#dceeef',
    communicating: '#92e2b5',
    complimenting: '#f69927',
    'fun-friendship': '#77d0b1',
    'opening-up': '#cecece',
    'managing-emotions': '#5cb4a6',
    'relish-coach': '#f9f5e4',
    'your-goal': '#febd11',
  },

  signupImageTints: ['#febd11', '#b0d3d5', '#92e2b5', '#ffa087', '#ffde82'],
  signupBackgrounds: [
    'rgba(254, 189, 17, 0.5)',
    'rgba(176, 211, 213, 0.5)',
    'rgba(146, 226, 181, 0.5)',
    'rgba(255, 160, 135, 0.5)',
    'rgba(255, 222, 130, 0.5)',
  ],
}

export default {
  ...colors,
  discoverFieldGuides: [
    colors.paleBlue,
    colors.beige,
    colors.palePeach,
    colors.lilac_pale,
    colors.light_teal,
    colors.creamDream,
  ],
  discoverSuccessStories: [colors.purple2, colors.red_peach, colors.yellow_light_mid],
  relationshipHealth: [
    colors.csi[0],
    colors.csi[5],
    colors.csi[8],
    colors.csi[10],
    colors.csi[15],
    colors.csi[18],
    colors.csi[21],
  ],
}
